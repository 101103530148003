import { IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useEffect, useState } from 'react';
import { DeleteUserDialog } from './DeleteUserDialog';

import { PermissoesUsuarioDialog } from './PermissoesUsuarioDialog';
import { Role, User } from '../../models/userType';
import { UserService } from '../../Services/UserService';
import { EnableComponent } from '../EnableComponent';
import { RoleEnum } from '../../models/enumType';


interface IProps {
    users: User[];
    selectUser: (userId: number) => void;
    reloadPage: () => void;
    userSelect: User | null;
}

export function TabelaUsuarios(props: IProps) {
    const service = new UserService();

    const [openDialogDelete, setOpenDeleteDialog] = useState(false);
    const [openModalPermissoes, setOpenModalPermissoes] = useState(false);
    const [roles, setRole] = useState<Role[]>([]);
    const [userSelect, setUserSelect] = useState<User | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const roles = await service.getAllRoles();
            setRole(roles);
        }

        fetchData();

    }, []);


    const closeDialogDelete = () => {
        setOpenDeleteDialog(false);
        props.reloadPage();
    }


    const closePermissoesModal = () => {

        setOpenModalPermissoes(false);
        props.reloadPage();
    }

    const columns: GridColDef[] = [
        { field: 'fullName', headerName: 'Nome Completo', flex: 1 },
        { field: 'login', headerName: 'Login de Acesso', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <EnableComponent role={RoleEnum.CadastrarEditarUsuario}>
                            <Tooltip placement="top" title="Editar Usuário">
                                <IconButton style={{ color: '#003958' }} size="large" onClick={() => props.selectUser(params.row.userId)}>
                                    <BsPencilSquare />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>

                        <EnableComponent role={RoleEnum.GerenciarPermissoesUsuario}>
                            <Tooltip placement="top" title="Gerenciar Permissões do Usuário">
                                <IconButton style={{ color: '#003958' }} size="large" onClick={() => {
                                    setUserSelect(params.row);
                                    setOpenModalPermissoes(!openModalPermissoes)
                                }}>
                                    <ManageAccountsIcon />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>

                        <EnableComponent role={RoleEnum.ExcluirUsuario}>
                            <Tooltip placement="top" title="Excluir Usuário" >
                                <IconButton style={{ color: '#003958' }} size="large" onClick={() => {
                                    setUserSelect(params.row);
                                    setOpenDeleteDialog(!openDialogDelete)
                                }}>
                                    <BsFillTrashFill />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>
                    </div>
                )
            }
        },
    ];

    return (
        <div id="tabela-usuarios" className="bg-white flex-col justify-center mx-0 my-5" style={{
            height: '85%'
        }}>
            <header className="w-full text-white text-center py-3 header" style={{
                borderRadius: '5px 5px 0px 0px'
            }}>TABELA DE USUÁRIOS</header>

            <div style={{ height: '80%', width: '100%' }}>
                <DataGrid
                    className='w-auto'
                    rows={props.users}
                    getRowId={(row) => row.userId}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: 10
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                />
            </div>

            {userSelect && (
                <DeleteUserDialog
                    closeDialogDelete={closeDialogDelete}
                    open={openDialogDelete}
                    userId={userSelect.userId}
                    reloadPage={props.reloadPage}
                />
            )}

            {userSelect && (
                <PermissoesUsuarioDialog
                    closePermissoesModal={closePermissoesModal}
                    open={openModalPermissoes}
                    user={userSelect}
                />
            )}

        </div>
    )
}