import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';


interface IProps {
    open: boolean;
    handleClose: Function;
    nameEvent: string;
    handleDelete: any;
}

export function DialogDelete(props: IProps) {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
         
            <DialogContent>
                <DialogContentText style={{fontSize: '20px'}}>
                    Tem certeza que deseja fazer apagar o evento {props.nameEvent} <br/>
                    <b>TODOS OS FUNCIONÁRIOS</b> serão apagados !
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={() => props.handleClose()} className='btn btn-default'>
                    Sair
                </button>
                <button onClick={props.handleDelete} autoFocus className='btn btn-danger'>
                    Apagar Evento
                </button>
            </DialogActions>
        </Dialog>
    )
}