import { Modal } from "react-bootstrap";
import { TimeSheet } from "../../models/employeeType";
import { useEffect, useState } from "react";
import { TimeSheetService } from "../../Services/TimeSheetService";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { CircularProgress, Paper } from "@mui/material";

interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    employeeName: string;
    employeeId: number;
}

export function ModalApontamentoHoras(props: IProps) {

    var service = new TimeSheetService();

    const [timeSheet, setTimeSheet] = useState<TimeSheet[]>([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        service.getEmployeeTimeSheetAsync(props.employeeId)
            .then((response: any) => {
                setTimeSheet(response.data);
                setLoading(false);
            });
    }, [])

    const formatDate = (value: any) => {
        if (value !== null) {
            let nameDay = new Date(value).toLocaleString('pt-br', { weekday: 'long' });
            let day = new Date(value).getDate();
            let month = new Date(value).getMonth() + 1;
            let time = new Date(value).toLocaleTimeString();

            return `${day}/${month} ${time} - ${nameDay}`
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'checkin', headerName: 'Entrada', flex: 1, headerAlign: 'center', align: 'center',
            renderCell: (params: any) => <span>{formatDate(params.value)}</span>
        },
        {
            field: 'checkout', headerName: 'Saida', flex: 1, headerAlign: 'center', align: 'center',
            renderCell: (params: any) => <span>{params.value ? formatDate(params.value) : '00:00'}</span>
        },
        {
            field: '', headerName: 'Tempo de Permanencia', flex: 1, headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                if (params.row.checkout != null) {
                    var diffInMs = new Date(params.row.checkout).getTime() - new Date(params.row.checkin).getTime();
                
                    let seconds = Math.floor(diffInMs / 1000);
                    let minutes = Math.floor(seconds / 60);
                    let hours = Math.floor(minutes / 60);
                
                    seconds = seconds % 60;
                    minutes = minutes % 60;
                    hours = hours % 24; // This ensures that it won't exceed 24 hours
                
                    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
                } else {
                    return <span>00:00</span>
                }
            }
        },
    ]

    const pad = (num: any) => String(num).padStart(2, '0');


    return (

        <Modal show={props.show} size="lg" centered>
            <Modal.Header closeButton onHide={() => {
                setTimeSheet([]);
                props.setShow(false)
            }} style={{ textAlign: 'center' }}>
                <Modal.Title className="flex flex-col justify-center w-full">
                    <span className="text-2xl">Apontamento de Horas</span>
                    <span className="text-xl">{props.employeeName}</span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ width: '100%', height: '300px' }}>
                    {timeSheet.length > 0 ? (
                        <React.Fragment>
                            <DataGrid
                                className='w-auto'
                                rows={timeSheet}
                                getRowId={(row) => row.eventUserTimeSheetId}
                                columns={columns}
                                pageSizeOptions={[10]} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Paper elevation={10} className="px-4 py-10 text-xl flex justify-center items-center" style={{ height: '300px' }}>
                                {
                                    loading ? <CircularProgress /> : "Esse Funcionário Não Possui Apontamentos"
                                }
                            </Paper>
                        </React.Fragment>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}