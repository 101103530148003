import { FC, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Row } from 'react-bootstrap';
import { AiFillPrinter } from 'react-icons/ai';
import '../../src/app.css';
import { MyButton } from './MyButton';
import QRCode from "react-qr-code";


import styles from '../Pages/Funcionarios/funcionarios.module.css';

interface IPulseiraComponent {
    codeNumber: number,
    fullName: string,
    staff: string,
    cpf: string,
    function: string,
    company: string
}

export const PulseiraComponent: FC<IPulseiraComponent> = (props) => {
    var contentRef: any = null;

    const [showQrCode, setShowQrCode] = useState(true);

    return (
        <div>
            <Row>
                <div ref={el => contentRef = el} className='ml-5 mt-1 flex items-center gap-x-2' >
                    <div>
                        {true && <QRCode value={props.codeNumber.toString()} size={60} />}
                    </div>
                    <div>
                        <div className="grid grid-cols-2">
                            <div className='gap-2 items-center'>
                                <b style={{ fontSize: '14px' }}>Nome:</b>
                                <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                    <b>{props.fullName}</b>
                                </span>
                            </div>

                            <div className='gap-2 items-center'>
                                <b style={{ fontSize: '14px' }}>Empresa:</b>
                                <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                    <b>{props.company}</b>
                                </span>
                            </div>
                        </div>

                        <div className="grid grid-cols-2">
                            <div className='gap-2 items-center'>
                                <b style={{ fontSize: '14px' }}>Setor:</b>
                                <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                    <b>{props.staff}</b>
                                </span>
                            </div>

                            <div className='gap-2 items-center'>
                                <b style={{ fontSize: '14px' }}>Função:</b>
                                <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                    <b>{props.function}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
            <Row>
                <ReactToPrint
                    content={() => contentRef}
                    trigger={() => (
                        <div className='row'>
                            <div className="col-6">

                            </div>
                            <div className="col-6">
                                <MyButton endIcon={<AiFillPrinter />}>
                                    Imprimir Pulseira
                                </MyButton>
                            </div>
                        </div>
                    )}
                />
            </Row>
        </div>
    )
}

