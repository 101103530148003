
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

export class ExcelHelper {
    static download = async (
        header: string[],
        data: any,
        nameFile: string
    ): Promise<void> => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
    
        const planilha = XLSX.utils.json_to_sheet(data);
    
        // let header = ['Evento', 'Funcionario', 'Email', 'Numero de Serie', 'CPF/RG', 'Empresa', 'Função', 'Staff', 'Criado no Evento', 'Checkin', 'Checkout', 'Tempo de Permanencia'];
    
        XLSX.utils.sheet_add_aoa(planilha, [header], { origin: 'A1' });
        const wb = { Sheets: { 'data': planilha }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', });
        const _data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(_data, `Planilha-${nameFile}` + fileExtension);
    };
}