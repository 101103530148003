import { toast } from "react-toastify";
import api from "./api";
import { Response } from "../models/responseType";
import { Role, User } from "../models/userType";
import { ServiceBase } from "./ServiceBase";

export class UserService extends ServiceBase {

    public async getAllUsers() {
        return new Promise<User[]>(async (resolve, reject) => {
            await api.get(`user`)
                .then(response => {
                    let result: Response<User[]> = response.data;
                    if (result.isSuccess) {
                        resolve(result.data);
                    } else {
                        toast.warn("Não foi possivel realizar a ação");
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    public async createUserAsync(data: any) {
        return new Promise(async (resolve, reject) => {
            await api.post(`user`, data)
                .then(response => {
                    let result: Response<boolean> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result);
                }).catch(error => {
                    this.handleError();
                    reject(error);
                });
        });
    }

    public async deleteUserAsync(userId: number) {
        return new Promise(async (resolve, reject) => {
            await api.delete(`user/${userId}`)
                .then(response => {
                    let result: Response<boolean> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result);
                }).catch(error => {
                    this.handleError();
                    reject(error);
                });
        });
    }

    public async updateUserAsync(data: any) {
        return new Promise(async (resolve, reject) => {
            await api.put(`user`, data)
                .then(response => {
                    let result: Response<boolean> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result);
                }).catch(error => {
                    this.handleError();
                    reject(error);
                });
        });
    }

    public async getAllRoles() {
        return new Promise<Role[]>(async (resolve, reject) => {
            await api.get(`user/roles`)
                .then(response => {
                    let result: Response<Role[]> = response.data;
                    if (result.isSuccess) {
                        resolve(result.data);
                    } else {
                        toast.warn("Não foi possivel realizar a ação");
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    public async updateEventRolesAsync(userId: number, selectRoles: string[], selectEvents: string[]) {
        return new Promise<Role[]>(async (resolve, reject) => {
            await api.put(`user/event-roles`, {
                userId: userId,
                roles: selectRoles,
                events: selectEvents
            }).then(response => {
                let result: Response<Role[]> = response.data;
                if (result.isSuccess) {
                    resolve(result.data);
                    toast.success(result.message)
                } else {
                    toast.warn("Não foi possivel realizar a ação");
                }
            }).catch(error => {
                reject(error);
            });
        });
    }

    public async getUserRoles(userId: number): Promise<User> {
        return new Promise(async (resolve, reject) => {
            await api.get(`user/event-roles/${userId}`)
                .then(response => {
                    let result: Response<User> = response.data;
                    if (result.isSuccess) {
                        resolve(result.data);
                    } else {
                        toast.warn("Não foi possivel realizar a ação");
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

}