import { useState, useRef, useCallback, useEffect } from 'react';
import { FaUser, FaIdCard, FaBriefcase, FaCamera } from 'react-icons/fa';
import Webcam from 'react-webcam';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { GoArrowRight } from "react-icons/go";
import { RiAlarmWarningFill } from "react-icons/ri";
import { Button, CircularProgress, Paper } from '@mui/material';
import { RiCheckDoubleFill } from "react-icons/ri";

import { IntegrationService } from '../../../Services/IntegrationService';
import { ResultFaceRecognition } from '../../../models/faceRecognitionType';
import { TypeLinkRegister } from '../../../enum/enums';
import { Employee } from '../../../models/employeeType';
import { EmployeeService } from '../../../Services/EmployeeService';
import { EventService } from '../../../Services/EventService';
import { useFormat } from '../../../hooks/useFormat';
import { Event } from '../../../models/eventType';

type Error = {
  name?: string,
  cpf?: string,
  setor?: string,
  function?: string,
  image?: string
}

type Props = {
  eventId: number;
  companyId: number;
  typeLink: TypeLinkRegister;
  reloadTable: () => void;
  setFinish: (finish: boolean) => void;
}

const formSchema = z.object({
  name: z.string().min(1, 'O Nome é obrigaório'),
  cpf: z.string().min(1, 'O CPF é obrigaório')
    .transform(cpf => cpf.replaceAll('.', '').replaceAll('-', '')),
  setor: z.string().min(1, 'O Setor é obrigaório'),
  function: z.string(),
  image: z.string()
});

type FormSchemaType = z.infer<typeof formSchema>;

const FormStepper = (props: Props) => {
  var service = new IntegrationService();
  var employeeService = new EmployeeService();
  var eventService = new EventService();

  const [errors, setErrors] = useState<Error>({});
  const { formatMaskRgOrCpf } = useFormat();
  const [step, setStep] = useState(1);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [faceDetect, setFaceDetect] = useState<ResultFaceRecognition>({} as ResultFaceRecognition);
  const [loading, setLoading] = useState(false);
  const [showFaceValidate, setShowFaceValidate] = useState(false);
  const [_event, setEvent] = useState<Event>();
  const {
    register,
    getValues
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: 'Henrique dos Santos',
      cpf: '47381509930',
      setor: 'Estacionamento',
      function: '',
      image: ''
    }
  });



  const captureImage = useCallback(() => {
    if (webcamRef.current == null) return;
    //@ts-ignore
    const imageSrc = webcamRef.current.getScreenshot();
    handlePhotoValidate(imageSrc);
  }, [webcamRef, setImgSrc]);

  const handlePhotoValidate = async (photoEmployee: any) => {
    setShowFaceValidate(false)
    setFaceDetect({} as ResultFaceRecognition);

    setLoading(true);

    setImgSrc(photoEmployee);
    var faceApiResponse = await service.handlePhotoValidateAsync(photoEmployee);
    setFaceDetect(faceApiResponse);

    setLoading(false);
    setShowFaceValidate(true);
  }

  const validateStep = (currentStep: any, hasEmployeePhoto: boolean) => {
    const newErrors: Error = {};
    if (currentStep === 1) {
      if (getValues('name') === '') newErrors.name = 'Campo e obrigatório';
      if (getValues('cpf') === '') newErrors.cpf = 'Campo e obrigatório';
      if (getValues('setor') === '') newErrors.setor = 'Campo e obrigatório';


    } else if (currentStep === 2 && hasEmployeePhoto) {
      if (!imgSrc) newErrors.image = 'A Foto e obrigatória';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (_event == null) return;

    if (step == 1) {
      if (!_event.hasEmployeePhoto) {
        setStep(3)
        return;
      }
    }

    if (validateStep(step, _event.hasEmployeePhoto)) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    debugger;

    let employee: Employee = {
      employeeId: 0,
      eventId: props.eventId,
      companyId: props.companyId,
      fullName: getValues('name'),
      cpf: getValues('cpf'),
      staff: getValues('setor'),
      function: getValues('function'),
      photo: imgSrc ?? '',
      photoUri: ''
    };

    var response = await employeeService.addEmployeeFromLinkRegister(employee);
    if (response.isSuccess) {
      props.setFinish(true);
    }
  };

  useEffect(() => {
    renderPhotoValidate();
  }, [faceDetect]);

  useEffect(() => {
    eventService.getEvent(props.eventId)
      .then((response) => {
        setEvent(response);
      });
  }, []);

  const renderPhotoValidate: any = () => {
    if (!showFaceValidate)
      return;

    if (faceDetect.isValid) {
      return (
        <Paper elevation={10} className="bg-green-300 border-green-500 px-4 py-2 text-center text-2xl flex justify-center items-center">
          <div className="row">
            <span className='flex text-green-600'>
              {faceDetect.message}
            </span>
          </div>
        </Paper>
      )
    } else {
      return (
        <>
          {imgSrc && (
            <Paper elevation={10} className="px-4 py-4 text-center flex justify-center items-center">
              <div className="row">
                <p className='text-red-500 text-xl font-mono flex-col'>
                  <span className='mx-2 flex justify-center'>
                    Foto Reprovada <RiAlarmWarningFill className='text-2xl text-red-500' />
                  </span>

                  <span>
                    {faceDetect.message}.
                  </span>
                </p>
              </div>
            </Paper>
          )}
        </>

      )
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-mono text-center">
              Insira os Dados Cadastrais
            </h2>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nome Completo</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaUser className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="name"
                  className={`block w-full pl-10 py-3 border sm:text-sm rounded-md`}
                  {...register('name')}
                />
              </div>
              {errors.name && <p className="mt-2 text-sm text-red-600">{errors.name}</p>}
            </div>
            <div>
              <label htmlFor="cpfRg" className="block text-sm font-medium text-gray-700">CPF/RG</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaIdCard className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="cpfRg"
                  className={`block w-full pl-10 py-3 sm:text-sm border rounded-md`}
                  placeholder="000.000.000-00"
                  {...register('cpf')}
                />
              </div>
              {errors.cpf && <p className="mt-2 text-sm text-red-600">{errors.cpf}</p>}
            </div>
            <div>
              <label htmlFor="setor" className="block text-sm font-medium text-gray-700">Setor</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaBriefcase className="h-5 w-5 text-gray-400" />
                </div>
                <input {...register('setor')}
                  className={`block w-full pl-10 py-3 sm:text-sm border rounded-md`}
                />
              </div>
              {errors.setor && <p className="mt-2 text-sm text-red-600">{errors.setor}</p>}
            </div>
            <div>
              <label htmlFor="function" className="block text-sm font-medium text-gray-700">Função</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaBriefcase className="h-5 w-5 text-gray-400" />
                </div>
                <input {...register('function')}
                  className={`block w-full pl-10 py-3 sm:text-sm border rounded-md`}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-mono mb-2 text-center">Precisamos Agora Tirar uma Foto</h2>
            <div className="max-w-md mx-auto">
              <p className="text-sm text-gray-500 mb-4 text-center">Por favor, habilite a web cam para tirar a foto.</p>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full rounded-lg shadow-lg"
              />
              <button
                type="button"
                onClick={captureImage}
                style={{ backgroundColor: "rgb(0, 57, 88)" }}
                className="mt-4 w-full inline-flex justify-center py-3 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <span className='flex justify-center items-center gap-2'>Tirar Foto <FaCamera className="mr-2 h-5 w-5" /></span>
                )}

              </button>
              <div className='mt-2'>{renderPhotoValidate()}</div>
              {errors.image && <p className="mt-2 text-sm text-red-600">{errors.image}</p>}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-mono mb-4 text-center">Valide os Seus Dados</h2>
            {_event && _event.hasEmployeePhoto && (
              <div className="sm:col-span-2 flex flex-col items-center">
                <dt className="text-sm font-medium text-gray-500">Foto</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <img src={imgSrc ?? ""} alt="Captured" className="w-48 h-48 rounded-lg shadow-lg" style={{ color: 'black', backgroundClip: 'black', fill: 'black' }} />
                </dd>
              </div>
            )}
            <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
              <div className="sm:col-span-1 sm:text-sm md:text-2xl">
                <dt className="font-medium text-gray-500">Nome:</dt>
                <dd className="mt-1 text-gray-900">{getValues('name')}</dd>
              </div>
              <div className="sm:col-span-1 sm:text-sm md:text-2xl">
                <dt className="font-medium text-gray-500">CPF/RG:</dt>
                <dd className="mt-1 text-gray-900">{formatMaskRgOrCpf(getValues('cpf'))}</dd>
              </div>
              <div className="sm:col-span-1 sm:text-sm md:text-2xl">
                <dt className="font-medium text-gray-500">Setor:</dt>
                <dd className="mt-1 text-gray-900">{getValues('setor')}</dd>
              </div>
              <div className="sm:col-span-1 sm:text-sm md:text-2xl">
                <dt className="font-medium text-gray-500">Função:</dt>
                <dd className="mt-1 text-gray-900">{getValues('function') ?? '-'}</dd>
              </div>
            </dl>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="py-8 lg:px-8">
      <Paper elevation={10} className='rounded-xl px-8 w-100'>
        <div className="mb-8">
          <div className="relative pt-1">
            <div className="flex mb-2 items-end justify-center">
              <span className='text-2xl font-mono my-2'>
                Passo {step} de 3 <br />
              </span>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded" style={{ backgroundColor: "#0039583d" }}>
              <div
                style={{ width: `${(step / 3) * 100}%`, backgroundColor: "rgb(0, 57, 88)" }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
              ></div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-8 w-full border-0 p-0 md:p-8 mb-8">
          {renderStep()}

          {step == 1 && (
            <div className="flex justify-content-end">
              <button
                type="button"
                onClick={handleNext}
                style={{ backgroundColor: "rgb(0, 57, 88)" }}
                className="mb-4 inline-flex items-end px-4 py-3 border border-transparent text-xl font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Próximo <GoArrowRight className='text-2xl ml-4' />
              </button>
            </div>
          )}

          {step == 2 && (
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handlePrev}
                className="mb-4 inline-flex items-center px-4 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Voltar
              </button>
              <button
                type="button"
                onClick={handleNext}
                style={{ backgroundColor: "rgb(0, 57, 88)" }}
                disabled={!faceDetect.isValid}
                className="mb-4 inline-flex items-end px-4 py-3 border border-transparent text-xl font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Próximo <GoArrowRight className='text-2xl ml-4' />
              </button>
            </div>
          )}

          {step == 3 && (
            <>
              <div className="flex justify-between">
                <Button
                  type="button"
                  onClick={handleSubmit}
                  fullWidth
                  className="mb-4 inline-flex items-end px-4 py-3 border border-transparent text-xl font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{ backgroundColor: "rgb(0, 57, 88)" }}
                >
                  Finalizar Cadastro <RiCheckDoubleFill className='text-2xl ml-4' />
                </Button>

              </div>
              <div className='text-xl'>
                Precisa corrigir alguma informação ? e so clicar no passo que deseja voltar
                <button style={{ color: "rgb(0, 57, 88)" }} onClick={() => setStep(1)} className="underline mx-2"> Dados Cadastrais </button>
                {_event && _event.hasEmployeePhoto && (
                  <>
                    ou <button style={{ color: "rgb(0, 57, 88)" }} onClick={() => setStep(2)} className="underline mx-2"> Tire uma Foto </button>
                  </>
                )}
              </div>
            </>
          )}
        </form>
      </Paper>
    </div >
  );
};

export default FormStepper;