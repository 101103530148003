import { Form } from "react-bootstrap";
import { zodResolver } from "@hookform/resolvers/zod";
import { BsSearch } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextField } from "@mui/material";

import { FilterTableEvent } from "../../models/filterType";
import { MyButton } from "../MyButton";

const schema = z.object({
    name: z.string(),
}).required();

type Props = {
    setFilter: (filter: FilterTableEvent) => void;
}

export function FilterTable(props: Props) {

    const { register, handleSubmit } = useForm<FilterTableEvent>({
        resolver: zodResolver(schema)
    });

    const handleFind = async (filter: FilterTableEvent) => {
        props.setFilter(filter);
    };

    return (

        <Form onSubmit={handleSubmit(handleFind)}
            className='row p-0 m-0 w-full my-3'
            style={{
                background: 'white',
                border: '0px',
                borderRadius: '10px',
                boxShadow: '0px 0px 21px 0px rgba(0,0,0,0.1)'
            }}>
            <header className="w-full text-white text-center p-3 header" style={{
                borderRadius: '5px 5px 0px 0px'
            }}>FILTROS DE BUSCA</header>

            <div className="row">
                <div className="col-md-12 flex justify-content-center gap-3 my-4">
                    <TextField label='Digite o nome do evento para pesquisar'
                        className='w-100'
                        {...register('name')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit(handleFind)();
                            }
                        }} />

                    <MyButton endIcon={<BsSearch />}
                        type='submit'
                        className='h-100'
                    >
                        Pesquisar
                    </MyButton>
                </div>
            </div>
        </Form>
    )
}