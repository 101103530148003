import { useContext, useState } from "react";
import { BsFillTrashFill, BsPencilSquare } from "react-icons/bs";
import { Dialog, DialogActions, IconButton, Paper, Switch, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

import { Company } from "../../models/eventType";
import { EnableComponent } from "../EnableComponent";
import { CompanyService } from "../../Services/CompanyService";
import { RoleEnum } from "../../models/enumType";
import { AiFillFileExcel } from "react-icons/ai";
import { useFormat } from "../../hooks/useFormat";
import AuthContext from "../../Context/auth";
import { MyButton } from "../MyButton";
import ModalImportarPlanilhaExcel from "./ModalImportarPlanilhaExcel";


type Props = {
    fetchData: () => void;
    editCompany: (company: Company) => void;
    companyEdit: Company | null
    companys: Company[]
}


export function TabelaEmpresas(props: Props) {

    var service = new CompanyService();
    const {
        formatMaskCpfOrCnpj,
        formatMaskPhone
    } = useFormat();

    const [openDialog, setOpenDialog] = useState(false);
    const [companyIdDelete, setCompanyIdDelete] = useState();
    const { hasRole } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);

    const handleDisable = async (companyId: number) => {
        await service.disableCompany(companyId);
        props.fetchData();
    }

    const handleDelete = async () => {
        setOpenDialog(false);
        await service.deleteCompany(companyIdDelete);
        props.fetchData();
    }

    const handleEdit = async (company: Company) => {
        props.editCompany(company);
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Fornecedor', flex: 1 },
        {
            field: 'cnpj',
            headerName: 'CNJP/CPF',
            flex: 1,
            renderCell: (params) => (<>{formatMaskCpfOrCnpj(params.value)}</>)
        },
        { field: 'email', headerName: 'Email de Contato', flex: 1 },
        {
            field: 'phone',
            headerName: 'Telefone de Contato',
            flex: 1,
            renderCell: (params) => {
                return formatMaskPhone(params.row.phone);
            }
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <div>
                        <EnableComponent role={RoleEnum.CadastrarEditarEmpresa}>
                            <Tooltip placement="top" title="Editar Fornecedor">
                                <IconButton style={{ color: '#003958' }} size="large" onClick={() => {
                                    handleEdit(params.row);
                                }}>
                                    <BsPencilSquare />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>

                        <EnableComponent role={RoleEnum.CadastrarEditarEmpresa}>
                            <Tooltip placement="top" title="Ativar/Inativar Fornecedor">
                                <Switch color="success"
                                    checked={params.row.active}
                                    onClick={() => handleDisable(params.row.companyId)} />
                            </Tooltip>
                        </EnableComponent>

                        <EnableComponent role={RoleEnum.ExcluirEmpresa}>
                            <Tooltip placement="top" title="Excluir Fornecedor">
                                <IconButton style={{ color: '#003958' }} size="large" onClick={() => {
                                    setCompanyIdDelete(params.row.companyId);
                                    setOpenDialog(true)
                                }}>
                                    <BsFillTrashFill />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>
                    </div>
                )
            }
        },
    ];

    const downloadExcel = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const planilha = XLSX.utils.json_to_sheet([{
            'Fornecedor': '',
            'CNPJ/CPF': '',
            'Email de Contato': '',
            'Telefone de Contato': '',
        }]);

        const wb = { Sheets: { 'data': planilha }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Planilha de Fornecedores" + fileExtension);
    }

    return (
        <Paper elevation={10} id="tabela-usuarios" className="bg-white flex-col justify-center mx-0 my-5" style={{
            height: '85%'
        }}>
            <header className="w-full text-white text-center py-3 header" style={{
                borderRadius: '5px 5px 0px 0px'
            }}>TABELA DE FORNECEDORES</header>
            <div className="row m-0 px-2">
                <div className="flex gap-3">
                    <MyButton className="my-3" endIcon={<AiFillFileExcel />}
                        onClick={downloadExcel}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa)}
                    >
                        BAIXAR PLANILHA PADRÃO
                    </MyButton>
                    <MyButton className="my-3" endIcon={<AiFillFileExcel />}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa)}
                        onClick={() => setShowModal(true)}
                    >
                        IMPORTAR PLANILHA EXCEL
                    </MyButton>
                </div>
            </div>
            <div style={{ height: '80%', width: '100%' }}>
                <DataGrid
                    className='w-auto'
                    rows={props.companys}
                    getRowId={(row: Company) => row.companyId}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: 10
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                />
            </div>



            <Dialog open={openDialog}>
                <div className="row m-0">
                    <p className="p-4">
                        Ao excluir esse fornecedor, todos os eventos que ele participou sera removido o vinculo do evento
                        com esse fornecedor a ser excluida, caso queira manter esse historico, desative o fornecedor ao inves de excluir.
                    </p>
                </div>
                <DialogActions>
                    <Button variant="default" onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDelete()}
                        className="d-flex items-center px-2 py-2">
                        Excluir Fornecedor <BsFillTrashFill className="mx-2" />
                    </Button>
                </DialogActions>
            </Dialog>

            <ModalImportarPlanilhaExcel
                setShow={setShowModal}
                show={showModal}
                reloadPage={props.fetchData}
            />
        </Paper>
    )
}