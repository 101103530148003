import { IconButton, Paper, Tooltip } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import React, { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Modal } from "react-bootstrap";

import { CompanyReport, EmployeeReport } from "../../models/ReportEventType"
import { BarItemIdentifier } from "@mui/x-charts";
import { ReportService } from "../../Services/ReportService";
import { MyButton } from "../MyButton";
import { useFormat } from "../../hooks/useFormat";
import { ModalApontamentoHoras } from "../Funcionarios/ModalApontamentoHoras";
import { TbClockPlus } from "react-icons/tb";

type Props = {
    data: CompanyReport,
    open: boolean,
    handleClose: () => void
}

export function DetalhesModal(props: Props) {
    var service = new ReportService();
    var { formatMaskRgOrCpf } = useFormat();
    const [xAxis, setXAxis] = React.useState<string[]>();
    const [yAxis, setYAxis] = React.useState<number[]>();
    const [employees, setEmployees] = React.useState<EmployeeReport[]>([])
    const [resultEmployees, setResultEmployees] = React.useState<EmployeeReport[]>([])
    const [showModalApontamento, setShowModalApontamentos] = React.useState(false);
    const [employeeSelect, setEmployeeSelect] = React.useState<EmployeeReport | null>(null);

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Funcionário', flex: 1
        },
        {
            field: 'cpf', headerName: 'CPF/RG', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => (<>{formatMaskRgOrCpf(params.value)}</>)
        },
        {
            field: 'companyName', headerName: 'Fornecedor', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'staff', headerName: 'Setor', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'function', headerName: 'Função', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'createAtEvent', headerName: 'Criado no Evento', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => (<>{params.value ? 'Sim' : 'Não'}</>)
        },
        {
            field: '', headerName: 'Apontamentos', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => (<>
                <Tooltip placement="top" title="Ver Apontamento de Horas">
                    <IconButton style={{ color: '#003958' }} size="large" onClick={() => handleTimeSheet(params.row)}>
                        <TbClockPlus />
                    </IconButton>
                </Tooltip>
            </>)
        }
    ];

    const handleTimeSheet = (employee: EmployeeReport) => {
        setEmployeeSelect(employee);
        setShowModalApontamentos(true);
    }

    useEffect(() => {
        let x = props.data.staffs.map(p => p.name);
        let y = props.data.staffs.map(p => p.qtd);

        setXAxis(x);
        setYAxis(y);

        service.getEmployeesModalAsync(props.data.eventId, props.data.companyId)
            .then(response => {
                setEmployees(response)
            })

    }, [props.data.companyId])

    return (
        <Modal
            size="xl"
            show={props.open}
            onClose={() => {
                setEmployeeSelect(null);
                props.handleClose()
            }}
            
        >
            <div className="p-12">
                <h3 className="text-center">{props.data.nameCompany}</h3>
                <div>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: xAxis }]}
                        series={[{
                            data: yAxis,
                            color: "#003958"
                        }]}
                        height={300}
                        onItemClick={(event: React.MouseEvent, barItemIdentifier: BarItemIdentifier) => {
                            let functionName = xAxis![barItemIdentifier.dataIndex];
                            let result = employees.filter(p => p.staff === functionName);
                            setResultEmployees(result)
                        }}
                    />
                    {resultEmployees.length > 0 ? (
                        <div id='tabela' style={{ height: '400px' }}>
                            <DataGrid
                                className='w-auto'
                                rows={resultEmployees}
                                getRowId={(row) => row.employeeId}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 5
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                            />


                        </div>
                    ) : (
                        <EmptyEmployee />
                    )}
                </div>
                <div className="row m-0 px-5 py-2">
                    <MyButton onClick={() => {
                        props.handleClose();
                        setResultEmployees([]);
                    }}>
                        FECHAR JANELA
                    </MyButton>
                </div>

                {employeeSelect && <ModalApontamentoHoras
                    employeeId={employeeSelect.employeeId}
                    employeeName={employeeSelect.name}
                    show={showModalApontamento}
                    setShow={setShowModalApontamentos}
                />}
            </div>
        </Modal>
    )

    function EmptyEmployee() {
        return (
            <div className='flex' style={{ height: '400px' }}>
                <span className='flex-none w-32 h-32' />
                <Paper elevation={10} className='flex justify-center items-center text-3xl mt-10 grow h-4/6'>
                    <div>
                        Selecione uma Função Representada no Gráfico
                    </div>
                </Paper>
                <span className='flex-none w-32 h-32' />
            </div>
        );
    }
}