import { toast } from "react-toastify";

import { ServiceBase } from "./ServiceBase";
import api from "./api";
import { Response } from "../models/responseType";
import { FormDIMEP } from "../models/dimepType";
import axios from "axios";
import { ResultFaceRecognition } from "../models/faceRecognitionType";

export class IntegrationService extends ServiceBase {

    async saveConfiguration(data: any) {
        return new Promise<Response<boolean>>((resolve, reject) => {
            api.post('Integration/save-config', data)
            .then(response => {
                let result: Response<boolean> = response.data;
                if (result.isSuccess && result.data) {
                    toast.success(result.message)
                    resolve(result);
                } else {
                    toast.warn(result.message)
                    reject(null);
                }
            }).catch(error => {
                reject(error);
            });
        });
    }

    async companyIsValidForIntegration(eventId: number) {
        return new Promise<Response<boolean>>((resolve, reject) => {
            api.post('Integration/company-validate', {
                eventId: eventId
            }).then(response => {
                let result: Response<boolean> = response.data;
                if (result.isSuccess) {

                    if (result.data) {
                        toast.success(result.message)
                    } else {
                        toast.warn(result.message)
                    }

                    resolve(result);
                } else {
                    toast.warn("Não foi possivel realizar a ação");
                }
            }).catch(error => {
                reject(error);
            });
        });
    }

    base64ToFile(base64: string, mimeType: string, fileName: string) {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ab], fileName, { type: mimeType });
    }


    async handlePhotoValidateAsync(image: any) {
        let formData = new FormData();
        let blob = this.base64ToFile(image, 'image/jpeg', 'foto.jpeg');
        formData.append("image", blob);
        return new Promise<ResultFaceRecognition>((resolve, reject) => {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_FACE_API}/validate`,
                data: formData
            }).then(response => {
                let result: ResultFaceRecognition = response.data.data;
                resolve(result);
            }).catch(error => {
                console.error(JSON.stringify(error))
                reject(error);
            });
        });
    }

    async registerDIMEP(data: FormDIMEP) {
        return new Promise<Response<string>>((resolve, reject) => {
            api.post('Integration/register-params-fornecedor', data)
                .then(response => {
                    let result: Response<string> = response.data;
                    if (result.isSuccess) {
                        toast.success(result.message)
                        resolve(result);
                    } else {
                        toast.error(result.errorList[0].errorMessage);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    async exportData(eventId: number) {
        return new Promise<Response<boolean>>((resolve, reject) => {
            api.post('Integration/send', {
                eventId: eventId
            }).then(response => {
                let result: Response<boolean> = response.data;
                if (result.isSuccess) {
                    toast.success(result.message)
                } else {
                    toast.error(result.message);
                }

                resolve(result);

            }).catch(error => {
                reject(error);
            });
        });
    }
}