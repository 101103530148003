import { FC, useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import { FormLogin } from '../../component/Login/FormLogin';
import BackgroundImage from '../../assets/background-login.jpg';
import ReactDOM from 'react-dom';
import styles from './index.module.css';

const Login: FC = () => {

    useEffect(() => {
        document.getElementById('body')!.style.overflow = 'hidden';
        document.getElementsByTagName('body')![0].style.overflow = 'hidden';
    }, [])

    return (
        <div className="row m-0 h-100 overflow-hidden">
            <div className={styles.backgroundImage}></div>
            <div className="d-flex justify-content-center align-items-center h-100">
                <FormLogin />
            </div>
        </div>
    )
}

export default Login;