

import { toast } from 'react-toastify';
import { Response } from '../models/responseType';

export abstract class ServiceBase {
    API_URL = process.env.REACT_APP_API_URL;

    handleAfterRequest = (response: Response<any>) => {

        if (response.isSuccess) {
            toast.success(response.message);
        } else {
            toast.error(response.errorList[0].errorMessage ?? response.message);
        }
    }

    handleError = () => {
        toast.success("Não foi possivel realizar a ação");
    }
}