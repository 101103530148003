import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes as Links } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import Home from '../Pages/Home';
import Login from '../Pages/Login';
import Funcionarios from '../Pages/Funcionarios';
import LinkCredenciamento from '../Pages/Funcionarios/CadastroPeloLink/LinkCredenciamento';
import Relatorios from '../Pages/TimeSheet';
import Loading from '../component/Loading';
import HeaderMenu from '../component/Menu/HeaderMenu';
import { Usuarios } from '../Pages/Usuarios';
import { Eventos } from '../Pages/Eventos';
import { Empresas } from '../Pages/Empresas';
import { EventReport } from '../Pages/Relatorios/EventReport';
import { IntegrationDimep } from '../Pages/IntegrationDimep';
import FormStepperComponent from '../component/Funcionarios/CadastroPeloLink/FormStepperComponent';

interface IRouterApp {

}

const Routes: React.FC<IRouterApp> = (props) => {

    const isAutenticated = () => sessionStorage.getItem('@App:token') ? true : false;

    return (
        <BrowserRouter>
            <Links>
                {isAutenticated() ? (
                    <>
                        <Route path="/" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="row h-100 bg-slate-200">
                                    <Home />
                                </div>
                            </Suspense>
                        } />

                        <Route path='/usuarios' element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="row h-100 bg-slate-200">
                                    <Usuarios />
                                </div>
                            </Suspense>
                        } />

                        <Route path="/events" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="row h-100 bg-slate-200">
                                    <Eventos />
                                </div>
                            </Suspense>
                        } />

                        <Route path="/companys" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="row h-100 bg-slate-200">
                                    <Empresas />
                                </div>
                            </Suspense>
                        } />

                        <Route path="/event/employees" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="row h-100 bg-slate-200">
                                    <Funcionarios />
                                </div>
                            </Suspense>
                        } />

                        <Route path="/reports" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="row h-100 bg-slate-200">
                                    <Relatorios />
                                </div>
                            </Suspense>
                        } />

                        <Route path="/event-reports" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="h-100">
                                    <EventReport />
                                </div>
                            </Suspense>
                        } />
                        <Route path="/integration" element={
                            <Suspense fallback={<Loading />}>
                                <div className='row'>
                                    <HeaderMenu />
                                </div>
                                <div className="h-100">
                                    <IntegrationDimep />
                                </div>
                            </Suspense>
                        } />

                    </>
                ) : (
                    <>
                        <Route path="/" element={<Navigate to="/login" />} />
                    </>
                )}
                <Route path="/login" element={
                    <Suspense fallback={<Loading />}>
                        <Login />
                    </Suspense>
                } />

                <Route path="/credenciamento/:pString" element={
                    <Suspense fallback={<Loading />}>
                        <LinkCredenciamento />
                    </Suspense>
                } />

            </Links>
        </BrowserRouter>
    );
};

export default Routes;