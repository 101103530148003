import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { Role, User } from "../../models/userType";
import { DualListPermissoes } from "./DualListPermissoes";

interface IProps {
    closePermissoesModal: () => void;
    open: boolean;
    user: User;
}

type RoleSelected = {
    roleId: number[];
}

export function PermissoesUsuarioDialog(props: IProps) {

    const {
        handleSubmit,
    } = useForm<RoleSelected>({
        defaultValues: {
            roleId: []
        }
    });

    const handle = (data: RoleSelected) => {
        
    }

    return (
        <Modal show={props.open} size="xl">
            <Modal.Header closeButton onHide={props.closePermissoesModal} style={{ textAlign: 'center' }}>
                <Modal.Title style={{ width: '100%' }}>
                    Gerencie as Permissões do(a) {props.user.fullName}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="b-0">
                <Form onSubmit={handleSubmit(handle)} className="border-0 w-full ">
                    <DualListPermissoes 
                        userId={props.user.userId}
                        closePermissoesModal={props.closePermissoesModal}
                    />
                </Form>
            </Modal.Body>

        </Modal>
    )
}