import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/font-awesome.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'react-dual-listbox/lib/react-dual-listbox.css.map';

import Routes from './Routes/routes';
import { AuthProvider } from './Context/auth';
import './app.css';

function App() {
  return (
    <>
      <div id="body" style={{ height: '100vh', overflowX: 'hidden'}}>
        <AuthProvider>
          <Routes />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </AuthProvider >
      </div >
    </>
  );
}

export default App;
