import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../Services/api';
import { Response } from '../models/responseType';
import { ResponseLogin, User } from '../models/userType';
import { RoleEnum } from '../models/enumType';

interface AuthContextData {
    signed: boolean;
    user: User | null,
    login(email: string, password: string): Promise<void>;
    logout(): void;
    hasRole(role: string): boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface Props {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {

    const [user, setUser] = useState<User | null>(
        sessionStorage.getItem("@App:user") ?
            JSON.parse(sessionStorage.getItem("@App:user") as string) : null
    );

    const login = async (email: string, password: string) => {
        await api.post(`account/login`, {
            email: email,
            password: password,
        }).then(response => {
            let result: Response<ResponseLogin> = response.data;
            if (result.isSuccess) {
                setUser(result.data.user);
                api.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;
                sessionStorage.setItem("@App:token", result.data.token);
                sessionStorage.setItem("@App:user", JSON.stringify(result.data.user));
                window.location.href = '/events';
            } else {
                toast.warn(response.data.message);
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const logout = () => {
        setUser(null);
        sessionStorage.removeItem("@App:token");
        sessionStorage.removeItem("@App:user");
        window.location.href = '/login';
    }

    const hasRole = (currentRole: RoleEnum): boolean => {
        
        if (user) {
            return user.roles.find(role => role === currentRole) ? true : false;
        }
        return false;
    }

    return (
        <AuthContext.Provider value={{
            signed: Boolean(user),
            login,
            user,
            logout,
            hasRole
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;