export function HeaderLogin() {
    return (
        <>
            <h3 className="font-mono" style={{ textAlign: 'center', color: 'black' }}>
                Controle Interno
            </h3>
            <h5 className="font-mono" style={{ textAlign: 'center', color: 'black' }}>
                Entre com os seus dados de login
            </h5>
        </>
    )
}