import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config: any) => {
    const token = `Bearer ${sessionStorage.getItem("@App:token")}`;
    config.headers['Authorization'] = token;
    config.headers['Content-Type'] = 'application/json';
    return config;
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        sessionStorage.removeItem('@App:token');
        window.location.href = '/login';
    }
    return error;
});


export default api;






