import { Button, Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import { useState } from "react";
import { ImUserCheck } from "react-icons/im";
import { FaCameraRetro } from "react-icons/fa";
import { BiSolidCheckShield } from "react-icons/bi";
import { TypeLinkRegister } from "../../../../enum/enums";


type Props = {
    nameEvent: string;
    nameCompany: string;
    qtdEmployees: number;
    photoIsRequired: boolean;
    typeLink: TypeLinkRegister
}

export function DialogInfo(props: Props) {

    const [openDialog, setOpenDialog] = useState(true);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"lg"}
            >
                <DialogContent>
                    <Paper elevation={10}>
                        <DialogTitle id="alert-dialog-title" className="text-center text-2xl">
                            {"Cadastro de Acesso"}
                        </DialogTitle>
                        <p className="px-4 pb-4 text-xl">
                            Bem-vindo ao sistema de cadastro para o evento <b>{props.nameEvent.toUpperCase()}</b>!<br />
                            Seguindo os passos abaixo, você poderá facilmente fazer o seu cadastro <br /><br />
                            {props.typeLink == TypeLinkRegister.LinkCompleto && (
                                <>Quantidade Liberada para Cadastro: {props.qtdEmployees}</>
                            )}
                        </p>
                    </Paper>
                    <Paper elevation={10}>
                        <DialogTitle id="alert-dialog-title" className="text-center text-2xl">
                            {"Passo a Passo de Como Funciona o Cadastro"}
                        </DialogTitle>
                        <ul className="px-4 pb-4 md:text-xl">
                            <li className="flex items-center mb-4">
                                <ImUserCheck className="mr-4 text-5xl" style={{ color: "#003958" }} />
                                Preencha todos os campos do formulário
                            </li>
                            {props.photoIsRequired && (
                                <li className="flex items-center mb-4">
                                    <FaCameraRetro className="mr-4 text-5xl" style={{ color: "#003958" }} />
                                    Em seguida precisamos tirar uma FOTO sua para que possa ter acesso ao evento.
                                </li>
                            )}
                            <li className="flex items-center">
                                <BiSolidCheckShield className="mr-4 text-5xl" style={{ color: "green" }} />
                                Valide todos os dados e clique no botão "FINALIZAR CADASTRO".
                            </li>
                        </ul>
                    </Paper>
                    <div className="row">
                        <Button variant="contained" onClick={() => setOpenDialog(false)} color="success" autoFocus fullWidth style={{ backgroundColor: "#003958" }}>
                            Fechar Janela
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}