import { ServiceBase } from "./ServiceBase";
import api from "./api";
import { Response } from "../models/responseType";
import { Employee } from "../models/employeeType";

export class EmployeeService extends ServiceBase {

    async addEmployeeFromLinkRegister(data: Employee) {
        return new Promise<Response<any>>(async (resolve, reject) => {
            await api.post('employee/AddEmployeeLinkRegister', data)
                .then((response: any) => {
                    let result: Response<any> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }
}