export class DateTimeHelper {

    public static CurrentMonthString(): string {
        let _months = [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembo", "Outubro", "Novembro", "Dezembro"
        ];

        return _months[new Date().getMonth()];
    }

    public static GetInitialDateFilter(): string {
        let _date = new Date();

        let year = _date.getFullYear();
        let month = _date.getMonth() + 1;   

        let filter = `${year}-${month < 10 ? `0${month}` : `${month}`}-01`;
        return filter;
    }

    public static GetFinalDateFilter(): string {
        let _date = new Date();


        let year = _date.getFullYear();
        let month = _date.getMonth() + 1;   

        let filter = `${year}-${month < 10 ? `0${month}` : `${month}`}-${30}`;
        return filter;
    }

 
}