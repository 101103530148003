
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';

import { useEffect, useState } from 'react';
import { Role, User } from '../../models/userType';
import { UserService } from '../../Services/UserService';
import { MyButton } from '../MyButton';
import { Event } from '../../models/eventType';
import { EventService } from '../../Services/EventService';


interface IProps {
    userId: number;
    closePermissoesModal: () => void;
}


export function DualListPermissoes(props: IProps) {

    const service = new UserService();
    const eventService = new EventService();

    const [roles, setRoles] = useState<Role[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [user, setUSer] = useState<User>();
    const [selectRoles, setSelectRoles] = useState<string[]>([]);
    const [selectEvents, setSelectEvents] = useState<string[]>([]);

    useEffect(() => {
        const fetchData = async () => {

            Promise.all([
                service.getAllRoles(),
                eventService.getAllEventsAsync(),
                service.getUserRoles(props.userId)
            ]).then((value: [Role[], Event[], User]) => {
                setRoles(value[0]);
                setEvents(value[1]);
                setUSer(value[2]);
                setSelectRoles(value[2].roles);
                setSelectEvents(value[2].events);
            });
        }

        fetchData();
    }, []);


    const handleSelectRole = (event: SelectChangeEvent<typeof selectRoles>) => {
        const {
            target: { value },
        } = event;
        setSelectRoles(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSelectEvent = (event: SelectChangeEvent<typeof selectRoles>) => {
        const {
            target: { value },
        } = event;
        setSelectEvents(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = async () => {
        await service.updateEventRolesAsync(props.userId, selectRoles, selectEvents);
        props.closePermissoesModal();
    }

    const RolesComponent = () => {
        return (
            <>
                <InputLabel id="select-telas">Permissões do Sistema</InputLabel>
                <Select
                    labelId="select-telas"
                    multiple
                    fullWidth
                    value={selectRoles}
                    onChange={handleSelectRole}
                    input={<OutlinedInput id="item-role" label="Permissões do Sistema" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                >
                    {roles.map((role: Role) => (
                        <MenuItem
                            key={role.roleId}
                            value={role.name}
                        >
                            {role.name}
                        </MenuItem>
                    ))}
                </Select>
            </>
        )
    }

    const EventsComponent = () => {
        return (
            <>
                <InputLabel id="select-telasa">Eventos Permitidos</InputLabel>
                <Select
                    labelId="select-telasa"
                    multiple
                    fullWidth
                    value={selectEvents}
                    onChange={handleSelectEvent}
                    input={<OutlinedInput id="item-event" label="Eventos Permitidos" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                >
                    {events.map((_event: Event) => (
                        <MenuItem
                            key={_event.eventId}
                            value={_event.name}
                        >
                            {_event.name}
                        </MenuItem>
                    ))}
                </Select>
            </>
        )
    }

    return (
        <div>
            <div>
                <RolesComponent />
            </div>

            <div className='my-3'>
                <EventsComponent />
            </div>

            <div className="row m-0 my-2">
                <MyButton endIcon={<ManageAccountsIcon />} onClick={handleSubmit}>
                    Registrar Permissões Selecionadas
                </MyButton>
            </div>
        </div>
    )



}