
import { toast } from 'react-toastify';
import { IEmployee, IFilterEmployee } from '../Interfaces/interfaces';
import api from './api';
import { showErrorValidation } from '../hooks/validation';
import { EnumEmployeeTimeSheet } from '../enum/enums';
import { Event, InfoEventCompany } from '../models/eventType';
import { ServiceBase } from './ServiceBase';
import { FilterTableEvent } from '../models/filterType';
import { UrlParams } from '../Helpers/UrlParams';
import { Employee } from '../models/employeeType';
import { Response } from "../models/responseType";

export class EventService extends ServiceBase {

    public API_URL = process.env.REACT_APP_API_URL;

    async getEventsToCloud(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await api.get(`event/GetEventsToCloud`)
                .then((response: any) => {
                    if (response.data.isSuccess) {
                        resolve(response.data.data);
                    } else {
                        resolve([]);
                    }
                }).catch(error => {
                    showErrorValidation(error);
                    reject(error);
                });
        });

    }

    async getEventCompanyAsync(eventId: number, companyId: number) {
        return new Promise<InfoEventCompany>(async (resolve, reject) => {
            await api.get(`event/eventCompany/${eventId}/${companyId}`)
                .then((response: any) => {
                    var result: Response<InfoEventCompany> = response.data;
                    resolve(result.data);
                }).catch(error => {
                    showErrorValidation(error);
                    reject(error);
                });
        });
    }


    async upOnCloud(callback: Function) {
        let promise = new Promise(async (resolve, reject) => {
            await api.post(`event/UpOnCloud`)
                .then((response: any) => {
                    resolve("Ok")
                    callback();
                }).catch((error: any) => {
                    callback();
                    reject(error)
                });
        });

        await toast.promise(promise,
            {
                pending: "Disponibilizando Online...",
                error: {
                    render({ data }: any) {
                        if (data.code === 'ERR_NETWORK')
                            return "Você não está conectado a internet, verifique sua conexão e tente novamente.";
                    }
                },
                success: "Eventos disponibilizados com sucesso."
            });
    }

    async uploadEventLogoAsync(eventId: number, file: any) {
        return new Promise<any>(async (resolve, reject) => {

            let formData = new FormData();
            formData.append("file", file);
            formData.append("eventId", eventId.toString());
            await api.post(`event/upload-logo/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                resolve(response);
            }).catch(error => showErrorValidation(error));
        });
    }

    async createTimeSheet(employee: IEmployee, typeValidate: string, callback: Function): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            switch (typeValidate) {
                case "checkin":
                    await this.checkin(employee);
                    callback();
                    break;
                case "checkout":
                    await this.checkout(employee);
                    callback();
                    break;
                default:
                    break;
            }
        });
    }

    async checkin(employee: IEmployee) {
        let checkin = {
            employeeId: employee.employeeId,
            eventId: employee.eventId,
            status: EnumEmployeeTimeSheet.checkin
        }
        return new Promise<any>(async (resolve, reject) => {
            await api.post(`eventTimeSheet/CheckinCheckout`, checkin)
                .then(response => {
                    if (response.data.isSuccess) {
                        toast.success(response.data.message);
                        resolve(response.data.data);
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => showErrorValidation(error));
        });
    }

    async checkout(employee: IEmployee) {
        let checkout = {
            employeeId: employee.employeeId,
            eventId: employee.eventId,
            status: EnumEmployeeTimeSheet.checkout
        }
        return new Promise<any>(async (resolve, reject) => {
            await api.post(`eventTimeSheet/CheckinCheckout`, checkout)
                .then(response => {
                    if (response.data.isSuccess) {
                        toast.success(response.data.message);
                        resolve(response.data.data);
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => showErrorValidation(error));
        });
    }


    async getUserEventsAsync(filter: FilterTableEvent): Promise<Event[]> {
        let queryString = UrlParams.makeParams(filter);

        return new Promise<Event[]>(async (resolve, reject) => {
            await api.get(`event/user-events?${queryString}`)
                .then((response: any) => {
                    let result: Response<Event[]> = response.data;
                    resolve(result.data);
                }).catch(error => {
                    this.handleError()
                });

        });
    }

    async getAllEventsAsync(): Promise<Event[]> {
        return new Promise<Event[]>(async (resolve, reject) => {
            await api.get(`event`)
                .then((response: any) => {
                    let result: Response<Event[]> = response.data;
                    resolve(result.data);
                }).catch(error => {
                    this.handleError()
                });

        });
    }

    async getEvent(idEvent: number): Promise<Event> {
        return new Promise<Event>(async (resolve, reject) => {
            await api.get(`event/FindById/${idEvent}`)
                .then((response: any) => {
                    let result: Response<Event> = response.data;
                    resolve(result.data);
                }).catch(error => this.handleError());
        });
    };

    async deleteEmployee(employeeId: number) {
        await api.delete(`employee/DeleteEmployee/${employeeId}`)
            .then((response: any) => {
                if (response.data.isSuccess) {
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => this.handleError());
    }

    async addEmployee(employee: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            await api.post(`employee/AddEmployee`, employee)
                .then((response: any) => {
                    let result: Response<Employee> = response.data;

                    if (result.isSuccess) {
                        resolve(result.data);
                        toast.success(result.message);
                    }
                    else {
                        toast.error(result.errorList[0].errorMessage);
                    }
                }).catch(error => this.handleError());
        });
    }

    async addEmployeeLink(employee: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            await api.post(`employee/addEmployeeLink`, employee, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response: any) => {
                let result: Response<Employee> = response.data;

                if (result.isSuccess) {
                    resolve(result.data);
                    toast.success(result.message);
                }
                else {
                    toast.error(result.errorList[0].errorMessage);
                }
            }).catch(error => this.handleError());
        });
    }


    async updateEmployee(employee: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            await api.post(`employee/EditEmployee?`, employee)
                .then((response: any) => {
                    resolve(response.data.data);
                    toast.success(response.data.message)
                }).catch(error => this.handleError());
        });
    }

    async addEventAsync(event: Event): Promise<Event> {
        return new Promise<Event>(async (resolve, reject) => {
            await api.post<Event>(`event/AddEvent`, event)
                .then((response: any) => {
                    let result: Response<Event> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result.data);
                }).catch((error) => {
                    this.handleError();
                    reject(error);
                });
        });
    }

    async deleteEventAsync(eventId: number) {
        await api.delete(`event/DeleteEvent/${eventId}`)
            .then((response: any) => {
                if (response.data.isSuccess) {
                    toast.success(response.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch((error: any) => { showErrorValidation(error); });
    }

    async getEmployees(filter: IFilterEmployee): Promise<any> {
        var promise = new Promise<any>(async (resolve, reject) => {
            await api.post(`employee/Find/`, filter)
                .then((response: any) => {
                    resolve(response.data.data);
                }).catch(error => { showErrorValidation(error); });
        });

        return promise;
    }

    async getEmployeeTimeSheets(filter: IFilterEmployee): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            await api.post(`eventTimeSheet/GetAll`, filter)
                .then((response: any) => {
                    if (response.data.isSuccess) {
                        resolve(response.data.data);
                    } else {
                        reject(response.data.message);
                    }
                }).catch(error => { showErrorValidation(error); });
        });
    }

    async getEmployee(employeeId: number): Promise<any> {
        var promise = new Promise<any>(async (resolve, reject) => {
            api.get(`employee/FindById/${employeeId}`)
                .then((response: any) => {
                    resolve(response.data.data);
                }).catch(error => { showErrorValidation(error); });
        });

        return promise;
    }

    async importarPlanilhaFuncionarios(dados: any) {
        let promise = new Promise<Array<string>>(async (resolve, reject) => {
            await api.post(`employee/AddRangeEmployee`, dados)
                .then(response => {
                    let result: Response<Array<string>> = response.data;
                    if (result.isSuccess)
                        resolve(new Array<string>());
                    else {
                        resolve(result.data);
                    }

                }).catch(error => {
                    showErrorValidation(error);
                });
        });

        return promise;
    }
}



