import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { BsArrowReturnRight } from "react-icons/bs";
import { useContext, useState } from "react";
import { TextField } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { MyButton } from "../MyButton";
import AuthContext from "../../Context/auth";
import { HeaderLogin } from "./HeaderLogin";
import { ErrorMessage } from "../ErrorMessage";

const schema = z.object({
    email: z.string().email("Digite um email válido").min(1, 'Campo obrigatório'),
    password: z.string().min(1, 'Campo obrigatório')
}).required()

type LoginSchema = z.infer<typeof schema>

export function FormLogin() {

    const context = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<LoginSchema>({
        resolver: zodResolver(schema),
    });

    const handleLogin = async (data: LoginSchema) => {
        setLoading(true);
        await context.login(data.email, data.password);
        setLoading(false);
    }

    return (
        <>
            <form className="py-16 bg-white font-extralight" onSubmit={handleSubmit(handleLogin)} style={{
                width: '30vw',
                position: 'relative',
                zIndex: 2
            }}>
                <div>
                    <HeaderLogin />
                    <div className="form-group mt-3">
                        <label className="flex"> <AccountCircleOutlinedIcon className="mr-1" /> Email </label>
                        <TextField className="form-control my-1"
                            {...register('email')}
                            fullWidth
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSubmit(handleLogin)();
                                }
                            }}
                        />
                        {errors.email && (<ErrorMessage errorMessage={errors.email?.message} />)}
                    </div>

                    <div className="form-group my-3">
                        <label className="flex"> <LockOpenIcon className="mr-1" />Senha</label>
                        <TextField
                            {...register('password')}
                            type="password"
                            className="form-control my-1"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSubmit(handleLogin)();
                                }
                            }}
                        />
                        {errors.password && (<ErrorMessage errorMessage={errors.password?.message} />)}
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <MyButton endIcon={<BsArrowReturnRight />} type="submit">
                            {loading ? 'Entrando...' : 'Entrar'}
                        </MyButton>
                    </div>
                </div>
                {/* <footer className="flex justify-center mt-3">
                    Esqueceu a senha ? <a href="#" className="mx-1">Clique aqui</a>
                </footer> */}
            </form>
        </>
    )
}