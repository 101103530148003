
import { SlExclamation } from "react-icons/sl";

type Prop = {
    errorMessage?: string | undefined;
}

export function ErrorMessage(props: Prop) {
    return (
        <div className="bg-red-200 flex py-2 px-1 mb-1" style={{
            borderRadius: 5,
            marginTop: 5,
            alignItems: 'center'
        }}>
            <SlExclamation className="fill-red-800 mr-2" style={{ fontSize: '20px' }} />
            <span className="text-red-800">{props.errorMessage}</span>
        </div>
    );
}