import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { ErrorMessage } from "../ErrorMessage";
import { MyButton } from "../MyButton";
import { HiOutlineSave } from 'react-icons/hi';
import { UserService } from "../../Services/UserService";
import { User } from "../../models/userType";
import { useContext, useEffect } from "react";
import { EnableComponent } from "../EnableComponent";
import { RoleEnum } from "../../models/enumType";
import AuthContext from "../../Context/auth";


interface IProps {
    user: User | null;
    resetParent: () => void;
}

export function FormUsuarios(props: IProps) {

    const { hasRole } = useContext(AuthContext);

    var service = new UserService();
    const formSchema = z.object({
        userId: z.number().default(0),
        fullName: z.string().min(1, 'O nome é obrigaório'),
        email: z.string().min(1, 'O email é obrigatório').email('O email é inválido'),
        login: z.string().min(1, 'O nome de usuário é obrigatório'),
        password: z.string().min(props.user ? 0 : 1, 'A senha deve ter no mínimo 6 caracteres'),
    });
    type FormType = z.infer<typeof formSchema>;

    const {
        register,
        handleSubmit,
        formState: {
            errors,
        },
        reset,
        setValue
    } = useForm<FormType>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            userId: 0,
            fullName: '',
            email: '',
            login: '',
            password: ''
        }
    });

    const handle = async (data: FormType) => {

        if (!props.user) {
            await service.createUserAsync(data);
        } else {
            await service.updateUserAsync(data);
        }
        
        reset();
        props.resetParent()
    }

    useEffect(() => {
        if (props.user) {
            setValue('userId', props.user.userId);
            setValue('fullName', props.user.fullName);
            setValue('login', props.user.login);
            setValue('email', props.user.email);
        }
    }, [props.user?.userId]);

    return (
        <>
            <div className="bg-white border-0 my-5 p-0 flex-col" style={{
                height: '85%',
                width: '100%'
            }}>
                <header className="w-full text-white text-center py-3 header" style={{
                    borderRadius: '5px 5px 0px 0px'
                }}>FORMULARIO DE CADASTRO</header>

                <Form onSubmit={handleSubmit(handle)} className="w-full border-0">
                    <div className="my-3">
                        <TextField
                            className="mb-2"
                            fullWidth
                            label="Nome Completo"
                            {...register('fullName')}
                            InputLabelProps={{ shrink: props.user ? true : undefined }}
                            disabled={!hasRole(RoleEnum.CadastrarEditarFuncionario)}
                        />
                        {errors.fullName && <ErrorMessage errorMessage={errors.fullName.message} />}
                    </div>

                    <div className="mb-3">
                        <TextField
                            className="mb-2"
                            fullWidth
                            label="Email"
                            InputLabelProps={{ shrink: props.user ? true : undefined }}
                            {...register('email')}
                            disabled={!hasRole(RoleEnum.CadastrarEditarFuncionario)}
                        />
                        {errors.email && <ErrorMessage errorMessage={errors.email.message} />}
                    </div>

                    <div className="mb-3">
                        <TextField
                            className="mb-2"
                            fullWidth
                            label="Nome de Usuário"
                            InputLabelProps={{ shrink: props.user ? true : undefined }}
                            {...register('login')}
                            disabled={!hasRole(RoleEnum.CadastrarEditarFuncionario)}
                        />
                        {errors.login && <ErrorMessage errorMessage={errors.login.message} />}
                    </div>

                    <div className="mb-3">
                        <TextField
                            className="mb-2"
                            fullWidth
                            label="Senha"
                            type="password"
                            InputLabelProps={{ shrink: undefined }}
                            {...register('password')}
                            disabled={!hasRole(RoleEnum.CadastrarEditarFuncionario)}
                        />
                        {errors.password && <ErrorMessage errorMessage={errors.password.message} />}
                    </div>

                    <div>
                        <MyButton type="submit" endIcon={<HiOutlineSave />}
                            disabled={!hasRole(RoleEnum.CadastrarEditarFuncionario)}
                        >
                            {props.user ? 'Atualizar' : 'Cadastrar'}
                        </MyButton>
                    </div>

                </Form>
            </div>
        </>
    )
}