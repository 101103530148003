import { FC, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IoIosWarning } from 'react-icons/io';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { FaFileDownload } from "react-icons/fa";
import { BsUpload } from 'react-icons/bs';
import React from 'react';

import { MyButton } from '../MyButton';
import { showErrorValidation } from '../../hooks/validation';
import { useConvert } from '../../hooks/useConvert';
import { CompanyService } from '../../Services/CompanyService';
import { Company } from '../../models/eventType';

interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    reloadPage: Function;
}


const ModalImportarPlanilhaExcel: FC<IProps> = (props) => {

    const service = new CompanyService();
    const { excelToJson } = useConvert();
    const [excelFile, setExcelFile] = useState(null);
    const [nameFile, setNameFile] = useState('SELECIONE A PLANILHA DE FORNECEDORES');
    const [logs, setLogs] = useState(new Array<string>());

    const handleClose = () => {
        props.setShow(!props.show);
        props.reloadPage();
        setLogs([]);
    };

    const importarPlanilha = async (dados: Company[]) => {
        let id = toast.loading("Importando Fornecedores...")
        service.importarPlanilhaExcel(1, dados)
            .then(response => {
                if (response.data.length === 0) {
                    toast.update(id, { render: 'Fornecedores importados com sucesso', type: 'success', isLoading: false, autoClose: 4000 })
                    props.setShow(false);
                }
                else {
                    toast.update(id, { render: 'Não foi possivel importar a planilha, veja os logs para saber oque houve', type: 'success', isLoading: false, autoClose: 4000 });
                    setLogs(response.data)
                }
            }).catch(error => {
                showErrorValidation(error)
            }).finally(() => props.reloadPage());
    }


    const handleSubmitExcel = async (e: any) => {
        e.preventDefault();
        const regex = /[^\d]/g;

        if (excelFile !== null) {
            let json = await excelToJson(excelFile);
            let companys: Company[] = [];
            let logs: string[] = [];

            companys = json.map((element: any, index: number) => {
                let company: Company = {
                    companyId: 0,
                    name: (element['Fornecedor'] ?? '').toString().trim(),
                    cnpj: (element['CNPJ/CPF'] ?? '').toString().trim(),
                    email: (element['Email de Contato'] ?? '').toString().trim(),
                    phone: (element['Telefone de Contato'] ?? '').toString().trim(),
                    qtdEmployees: 0,
                    active: true
                };
                debugger;

                company.cnpj = company.cnpj.replace(regex, '');
                company.phone = company.phone.replace(regex, '');
                validateFields(company, index + 1, logs);

                return company;
            });


            if(logs.length == 0)
                await importarPlanilha(companys);
            else 
                setLogs(logs);
        }
    }


    const validateFields = (company: Company, line: number, logs: string[]) => {
        if (company.name != undefined && company.name == '')
            logs.push(`Linha[${line}]: Nome é obrigatório`);
        // if (company.cnpj != undefined && company.cnpj == '')
            // logs.push(`Linha[${line}]: CPF/CNPJ é obrigatório`);
        // if (company.phone != undefined && company.phone == '')
        //     logs.push(`Linha[${line}]: Nome é obrigatório`);
        if (company.email != undefined && company.email == '')
            logs.push(`Linha[${line}]: Nome é obrigatório`);
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <Modal show={props.show}>
            {logs.length === 0 ? (
                <div id='modal-importar'>
                    <Modal.Header closeButton onHide={handleClose} style={{ textAlign: 'center' }}>
                        <Modal.Title style={{ width: '100%' }}>Importar Planilha de Fornecedores</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
                        <Form style={{ border: '0px', padding: '5px 0px' }}>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Button component="label"
                                    variant="contained"
                                    endIcon={<FaFileDownload />}
                                    onChange={(event: any) => {
                                        setExcelFile(event.target.files[0]);
                                        setNameFile(event.target.files[0].name);
                                    }}
                                    style={{ background: '#003958', color: 'white', width: '100%' }}>
                                    {nameFile}
                                    <VisuallyHiddenInput type="file" />
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <MyButton endIcon={<BsUpload />}
                            className="mx-2"
                            onClick={handleSubmitExcel}>
                            Realizar Importação
                        </MyButton>
                    </Modal.Footer>
                </div>
            ) : (
                <div id='modal-logs'>
                    <Modal.Header closeButton onHide={handleClose} style={{ textAlign: 'center' }}>
                        <Modal.Title style={{ width: '100%' }}>Logs da Importação</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', maxHeight: '450px' }}>
                        <ul className='py-3'>
                            {logs.map(log => {
                                return (
                                    <li style={{ borderBottom: '0.5px solid #ccc', marginBottom: '5px', listStyle: 'none' }}>
                                        <div className='flex justify-start items-center gap-2'>
                                            <IoIosWarning className='icon-warning' />
                                            <label>{log}</label>
                                        </div>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </Modal.Body>
                </div>
            )}
        </Modal>
    )
}

export default ModalImportarPlanilhaExcel;

