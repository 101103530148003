import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Checkbox, InputLabel, TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { useContext } from "react";
import { HiOutlineSave } from "react-icons/hi";

import { ErrorMessage } from "../ErrorMessage";
import { MyButton } from "../MyButton";
import AuthContext from "../../Context/auth";
import { RoleEnum } from "../../models/enumType";
import { Event } from "../../models/eventType";
import { EventService } from "../../Services/EventService";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";

interface IProps {
    fetchData: () => void;
}

export function FormEventos(props: IProps) {

    const { hasRole } = useContext(AuthContext);
    const service = new EventService();

    const formSchema = z.object({
        eventId: z.number().default(0),
        name: z.string().min(1, 'O nome é obrigaório'),
        initialDate: z.coerce.date({ required_error: 'A data de início é obrigatória' }),
        finalDate: z.coerce.date({ required_error: 'A data de término é obrigatória' }),
        hasEmployeePhoto: z.coerce.boolean().default(false)
    });

    const {
        register,
        handleSubmit,
        formState: {
            errors,
        },
        reset
    } = useForm<Event>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            eventId: 0,
            name: '',
            initialDate: new Date(DateTimeHelper.GetInitialDateFilter()),
            finalDate: new Date(DateTimeHelper.GetFinalDateFilter()),
        }
    });

    const handle = async (data: Event) => {
        await service.addEventAsync(data);
        reset();
        props.fetchData();
    }

    return (
        <div className="bg-white border-0 my-5 p-0 flex-col rounded" style={{
            height: '85%',
            width: '100%'
        }}>
            <header className="w-full text-white text-center py-3 header" style={{
                borderRadius: '5px 5px 0px 0px'
            }}>FORMULARIO DE CADASTRO</header>

            <Form onSubmit={handleSubmit(handle)} className="w-full border-0">
                <div className="my-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        label="Nome do Evento"
                        {...register('name')}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEvento)}
                    />
                    {errors.name && <ErrorMessage errorMessage={errors.name.message} />}
                </div>

                <div className="mb-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        type="date"
                        label="Data de Inicio"
                        {...register('initialDate')}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEvento)}
                        InputLabelProps={{ shrink: true }}
                    />
                    {errors.initialDate && <ErrorMessage errorMessage={errors.initialDate.message} />}
                </div>

                <div className="mb-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        type="date"
                        label="Data de Término"
                        {...register('finalDate')}
                        InputLabelProps={{ shrink: true }}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEvento)}
                    />
                    {errors.finalDate && <ErrorMessage errorMessage={errors.finalDate.message} />}
                </div>

                <div className="mb-3 flex justify-start items-center">
                    <Checkbox
                        {...register('hasEmployeePhoto')}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEvento)} />
                    <InputLabel id="label">Armazenar a Foto dos Funcionários</InputLabel>
                </div>

                <div>
                    <MyButton type="submit" endIcon={<HiOutlineSave />}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEvento)}
                    >
                        {'Cadastrar'}
                    </MyButton>
                </div>
            </Form>
        </div>
    )
}