import { useEffect, useState } from "react";
import { Company } from "../../models/eventType";
import { TabelaEmpresas } from "../../component/Empresas/TabelaEmpresas";
import { FormEmpresas } from "../../component/Empresas/FormEmpresas";
import { CompanyService } from "../../Services/CompanyService";



export function Empresas() {
    
    var service = new CompanyService();
    const [companys, setCompanys] = useState<Company[]>([]);
    const [_editCompany, setEditCompany] = useState<Company | null>(null);

    const editCompany = (company: Company) => {
        setEditCompany(company);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const companys = await service.getAllCompanys();
        setCompanys(companys);
        setEditCompany(null);
    }

    return (
        <div className="row m-0">
            <div id="container-cadastro" className="col-md-3 flex justify-center mx-0">
                <FormEmpresas 
                    company={_editCompany} 
                    fetchData={fetchData}
                    setEditCompany={setEditCompany} 
                />
            </div>
            <div className="col-md-9">
                <TabelaEmpresas 
                    companys={companys}
                    fetchData={fetchData}
                    companyEdit={_editCompany} 
                    editCompany={editCompany} 
                />
            </div>
        </div>
    );
}