


import { Autocomplete, Paper, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';

import { MyButton } from '../../component/MyButton';
import { Event } from '../../models/eventType';
import { EventService } from '../../Services/EventService';
import { TabelaRelatorio } from '../../component/Relatorios/TabelaRelatorio';
import { ReportService } from '../../Services/ReportService';
import { ReportEvent } from '../../models/ReportEventType';

export function EventReport() {
    var eventService = new EventService();
    var reportService = new ReportService();

    const [events, setEvents] = React.useState<Event[]>([]);
    const [numberRandom, setNumberRandom] = React.useState<number>(0);
    const [search, setSearch] = React.useState<Event>();
    const [report, setReport] = React.useState<ReportEvent>();


    useEffect(() => {
        eventService.getUserEventsAsync({ name: '' })
            .then((response) => {
                setEvents(response);
            })
    }, []);

    const fetchData = () => {
        if (search && search.eventId > 0) {
            reportService.getEventReport(search.eventId)
                .then((response: any) => {
                    setReport(response);
                })
        }
    }

 
    return (
        <>
            <div className='flex'>
                <span className='flex-none w-32 h-32' />
                <Filter />
                <span className='flex-none w-32 h-32' />
            </div>

            {report && search && search?.eventId > 0 ? (
                <div>
                    <TabelaRelatorio data={report} eventName={search.name} />
                </div>
            ) : (
                <EmptyEvent />
            )}
        </>
    )


    function Filter() {
        return (
            <Paper elevation={10} id="filter" className='grid grid-cols-2 gap-4 mt-4 px-10 py-4 grow'>
                <Autocomplete
                    key={numberRandom}
                    value={search?.name}
                    disablePortal
                    id="combo-box-demo"
                    options={events.map((p: Event) => `${p.name}`)}
                    fullWidth
                    onInputChange={(event: any, value: any) => {
                        let _event = events.find(p => p.name === value);
                        if (_event != null)
                            setSearch(_event);
                    }}
                    renderInput={(params) => <TextField {...params} label="Digite o nome do evento para pesquisar" />}
                />

                <MyButton endIcon={<BsSearch />} type='submit' className='h-100' onClick={() => {
                    fetchData();
                    setNumberRandom(numberRandom + 1);
                }}>
                    Pesquisar
                </MyButton>
            </Paper>
        );
    }

    function EmptyEvent() {
        return (
            <div className='flex' style={{ height: '100%' }}>
                <span className='flex-none w-32 h-32' />
                <Paper elevation={10} className='flex justify-center items-center text-3xl mt-10 grow h-4/6'>
                    <div>
                        Pesquise um Evento para Gerar o Relatório
                    </div>
                </Paper>
                <span className='flex-none w-32 h-32' />
            </div>
        );
    }

}