import  { useState, FC } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TfiReload } from 'react-icons/tfi';

import api from '../../Services/api';
import { showErrorValidation } from '../../hooks/validation';
import { IEmployee } from '../../Interfaces/interfaces';
import { MyButton } from '../MyButton';



interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    reloadPage: Function;
    eventId: number;
    funcionarios: IEmployee[];
}

interface IStaffCodeNumber {
    staff: string
    initialRange: number,
    eventId: number
}
      
const ModalGerarCodeNumber: FC<IProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [staffs, setStaffs] = useState<IStaffCodeNumber[]>([]);

    if(staffs.length === 0 && props.funcionarios.length > 0){
        var currentStaffs: IStaffCodeNumber[] = [];

        props.funcionarios.forEach(func => {
            if(currentStaffs.find(p => p.staff === func.staff))
                return;
            currentStaffs.push({
                staff: func.staff,
                initialRange: 0,
                eventId: props.eventId
            })
        })

        setStaffs(currentStaffs)
    }

    const handleClose = () => {
        props.setShow(!props.show);
        props.reloadPage();
    };

    const handleSubmit = async () => {

        try {

            await api.post(`${process.env.REACT_APP_API_URL}employee/GenerateCodeNumbers`, staffs)
                .then((response: any) => { 
                    if (response.data.isSuccess) {
                        handleClose();
                        toast.success(response.data.message);
                    } else {
                        toast.warn(response.data.message);
                    }
                    setLoading(true);
                }).catch(error => { showErrorValidation(error); })

        } catch (error: any) {

        }
    }

    const setValueStaff = (staff: string, event: any) => {
        staffs.forEach(p => {
            if(p.staff === staff)
                p.initialRange = Number(event.target.value);
        })
        setStaffs(staffs)
    }


    return (
        <>
            <Modal show={props.show} size='lg'>
                <Modal.Header closeButton onHide={handleClose} style={{ textAlign: 'center' }}>
                    <Modal.Title style={{ width: '100%' }}>
                        Gerador de Numeros de Série
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form style={{ border: '0px', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center'}}>
                        {
                            staffs && staffs.map((value, index) => {
                                return (
                                    <div key={index} className="mb-3 row">
                                        <div className="col-md-4">
                                            <label style={{textAlign: 'center', lineHeight: '35px', fontWeight: '500'}}>{value.staff}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input className='form-control' type="number" onChange={(event:any) => setValueStaff(value.staff, event) }/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <MyButton endIcon={<TfiReload />} onClick={handleSubmit}>
                        Gerar Numeros 
                    </MyButton>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ModalGerarCodeNumber;