import * as CryptoJS from 'crypto-js';


export function useEncrypt() {

    function encrypt(data: any): string {
        let jsonData = JSON.stringify(data);
        let encryptData = CryptoJS.AES.encrypt(jsonData, process.env.REACT_APP_TOKEN!);
        return encryptData.toString();
    }

    function decrypt(encryptText: string) {
        let decryptData = CryptoJS.AES
            .decrypt(encryptText, process.env.REACT_APP_TOKEN!)
            .toString(CryptoJS.enc.Utf8);

        return decryptData;
    }

    return {
        encrypt,
        decrypt
    }
}