import api from "./api";
import { Response } from "../models/responseType";
import { ServiceBase } from "./ServiceBase";
import { EventCompanysInfoExcel } from "../models/exportDataType";

export class ExportDataService extends ServiceBase {

    readCompanyWithEmployeesAsync(eventId: number): Promise<EventCompanysInfoExcel[]> {
        return new Promise(async (resolve, reject) => {
            await api.get(`exportData/read-company-with-employees/${eventId}`)
                .then((response: any) => {
                    let result: Response<EventCompanysInfoExcel[]> = response.data;
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }
}