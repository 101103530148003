import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { AiFillFileExcel } from "react-icons/ai";
import { BsFillTrashFill, BsPencilSquare } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { RoleEnum } from "../../models/enumType";
import { EventService } from "../../Services/EventService";
import api from "../../Services/api";
import { Event } from "../../models/eventType";
import { EnableComponent } from "../EnableComponent";
import { DialogDelete } from "../../Pages/Home/DialogDelete";
import { ExcelHelper } from "../../Helpers/ExcelHelper";
import { FilterTable } from "./FilterTable";
import { FilterTableEvent } from "../../models/filterType";
import { ModalRegistrarEmpresa } from "./ModalRegistrarEmpresa";

type Props = {
    fetchData: () => void;
    setFilter: (filter: FilterTableEvent) => void;
    events: Event[];
}

export function TabelaEventos(props: Props) {

    const service = new EventService();
    const navigate = useNavigate();
    const [eventDelete, setEventDelete] = useState<Event>({} as Event);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalCompany, setModalCompany] = useState(false);
    const [eventId, setEventId] = useState<number>(0);


    const handleExcel = async (row: Event) => {

        if (row.eventId > 0 && row != null) {
            var promise = new Promise(async (resolve, reject) => {
                await api.get(`${process.env.REACT_APP_API_URL}event/GetExcelData/${row.eventId}`)
                    .then((response: any) => {
                        if (response.data.isSuccess) {
                            ExcelHelper.download(
                                ['Evento', 'Funcionario', 'Email', 'Numero de Serie', 'CPF/RG', 'Empresa', 'Função', 'Staff', 'Criado no Evento', 'Checkin', 'Checkout', 'Tempo de Permanencia'],
                                response.data.data,
                                row.name
                            );
                            resolve(response.data.data);
                        } else {
                            resolve(response.data.message);
                        }
                    });
            });

            await toast.promise(promise,
                {
                    pending: "Gerando planilha do evento...",
                    error: "Não foi possivel fazer o download da planilha.",
                    success: `Planilha do evento gerada com sucesso!`
                });
        }
    };

    const columns: GridColDef[] = [
        // {
        //     field: 'logoUri', headerName: 'Logo', headerAlign: 'center', align: 'center',
        //     renderCell: (params: any) => {
        //         return (
        //             <Avatar
        //                 alt="Remy Sharp"
        //                 src={params.value}
        //             >
        //                 <Button component="label"
        //                     variant="outlined"
        //                     className="h-100"
        //                     onChange={(event: any) => {
        //                         handleFileEvent(params.row.eventId, event.target.files[0]);
        //                     }}
        //                     style={{ background: '#ccc', color:'black', border: 'none' }}>
        //                         <ImageNotSupportedIcon style={{color: '#0a0000a6'}} />
        //                     <FileInput />
        //                 </Button>
        //             </Avatar>
        //         )
        //     }
        // },
        { field: 'name', headerName: 'Evento', flex: 1 },
        {
            field: 'initialDate', headerName: 'Data de Inicio', flex: 1,
            renderCell: (params: any) => <span>{new Date(params.value).toLocaleDateString()}</span>
        },
        {
            field: 'finalDate', headerName: 'Data de Término', flex: 1,
            renderCell: (params: any) => <span>{new Date(params.value).toLocaleDateString()}</span>
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>

                        <EnableComponent role={RoleEnum.ConsultarFuncionario}>
                            <Tooltip placement="top" title="Visualizar os Funcionários">
                                <IconButton style={{ color: '#003958', cursor: 'pointer' }}
                                    onClick={() => navigate(`/event/employees`, { state: { eventId: params.row.eventId } })}>
                                    <BsPencilSquare />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>

                        <EnableComponent role={RoleEnum.ConsultarFornecedoresEvento}>
                            <Tooltip placement="top" title="Registrar os Fornecedores no Evento">
                                <IconButton style={{ color: '#003958', cursor: 'pointer' }} size="large"
                                    onClick={() => {
                                        setEventId(params.row.eventId)
                                        setModalCompany(true)
                                    }}>
                                    <AddBusinessIcon />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>

                        <Tooltip placement="top" title="Exportar Relatório do Evento">
                            <IconButton style={{ color: '#003958', cursor: 'pointer' }} size="large"
                                onClick={() => handleExcel(params.row)}>
                                <AiFillFileExcel />
                            </IconButton>
                        </Tooltip>

                        <EnableComponent role={RoleEnum.ExcluirEvento}>
                            <Tooltip placement="top" title="Excluir Evento">
                                <IconButton style={{ color: '#003958', cursor: 'pointer' }} size="large"
                                    onClick={() => handleClickOpen(params.row)}>
                                    <BsFillTrashFill />
                                </IconButton>
                            </Tooltip>
                        </EnableComponent>
                    </div>
                )
            }
        },
    ];

    const handleClickOpen = (event: any) => {
        setEventDelete(event);
        setConfirmModal(true);
    };

    const handleClose = () => {
        props.fetchData();
        setModalCompany(false);
        setEventId(0);
        setEventDelete({} as Event);
        setConfirmModal(false);
    };

    const handleDelete = async () => {
        if (eventDelete.eventId > 0) {
            await service.deleteEventAsync(eventDelete.eventId);
            handleClose();
        }
    };

    const handleFileEvent = async (eventId: number, file: any) => {
        if (file === null) return;

        let toastId = toast.loading('Enviando logo do evento...');
        await service.uploadEventLogoAsync(eventId, file);

        toast.update(toastId, {
            render: 'O Logo do evento foi enviado com sucesso!',
            type: 'success',
            isLoading: false,
            autoClose: 2000
        });
    }

    return (
        <>
            <div className="mt-5">
                <FilterTable setFilter={props.setFilter} />
            </div>

            <div id="tabela-usuarios" className="bg-white flex-col justify-center mx-0" style={{
                height: '60%'
            }}>

                <header className="w-full text-white text-center py-3 header" style={{
                    borderRadius: '5px 5px 0px 0px'
                }}>TABELA DE EVENTOS</header>


                <div style={{ height: '90%', width: '100%' }}>
                    <DataGrid
                        className='w-auto'
                        rows={props.events}
                        getRowId={(row) => row.eventId}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    page: 0,
                                    pageSize: 10
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                    />
                </div>

                {eventDelete.eventId > 0 && (
                    <DialogDelete
                        open={confirmModal}
                        handleClose={handleClose}
                        nameEvent={eventDelete.name}
                        handleDelete={handleDelete} />
                )}

                {eventId > 0 && (
                    <ModalRegistrarEmpresa
                        reloadPage={props.fetchData}
                        handleClose={handleClose}
                        show={modalCompany}
                        eventId={eventId}
                    />
                )}

            </div>
        </>
    );
}