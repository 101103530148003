export enum RoleEnum {
    ConsultarUsuario = 'Consultar Usuario',
    CadastrarEditarUsuario = 'Cadastrar/Editar Usuario',
    ExcluirUsuario = 'Excluir Usuario',
    GerenciarPermissoesUsuario = 'Gerenciar Permissoes do Usuario',
    ConsultarEvento = 'Consultar Evento',
    CadastrarEditarEvento = 'Cadastrar/Editar Evento',
    ExcluirEvento = 'Excluir Evento',
    ConsultarFuncionario = 'Consultar Funcionario',
    CadastrarEditarFuncionario = 'Cadastrar/Editar Funcionario',
    ExcluirFuncionario = 'Excluir Funcionario',
    RealizarCheckinCheckoutFuncionario = 'Realizar Checkin/Checkout do Funcionario',
    ConsultarRelatorioEvento = 'Consultar Relatorio de Evento',
    ConsultarRelatorioEmpresas = 'Consultar Relatorio de Fornecedores',
    CadastrarEditarEmpresa = 'Cadastrar/Editar Fornecedores',
    ConsultarEmpresa = 'Consultar Fornecedores',
    ExcluirEmpresa = 'Excluir Fornecedores',
    GerarLinkCadastroFornecedor = 'Gerar Link de Cadastro Fornecedores',
    ConsultarFornecedoresEvento = 'Consultar Fornecedores do Evento',
}

export enum EnumQuantity {
    Add = 1,
    Sub = 2
}