import { FC, useContext, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BsFillTrashFill, BsPencilSquare, BsSearch } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import { AiFillFileExcel } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { FiUserPlus } from 'react-icons/fi';

import { HiOutlineDocumentReport } from 'react-icons/hi';
import { BsQrCode } from 'react-icons/bs';
import { AiFillPrinter } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import { TbClockPlus } from "react-icons/tb";

import styles from './funcionarios.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalCriarFuncionario from '../../component/Funcionarios/ModalCriarFuncionario';
import ModalImportarPlanilhaExcel from '../../component/Funcionarios/ModalImportarPlanilhaExcel';
import { EventService } from '../../Services/EventService';
import { IEmployee, IFilterEmployee } from '../../Interfaces/interfaces';
import Header from '../../component/Header';
import ModalPrintFuncionario from '../../component/Funcionarios/ModalPrintFuncionario';
import ModalGerarCodeNumber from '../../component/Funcionarios/ModalGerarCodeNumber';

import { MyButton } from '../../component/MyButton';
import { IoMdArrowBack } from 'react-icons/io';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Avatar, TextField, Tooltip } from '@mui/material';
import { EnableComponent } from '../../component/EnableComponent';
import { RoleEnum } from '../../models/enumType';
import AuthContext from '../../Context/auth';
import { CompanyService } from '../../Services/CompanyService';
import { Company } from '../../models/eventType';
import { useFormat } from '../../hooks/useFormat';
import React from 'react';
import { ModalApontamentoHoras } from '../../component/Funcionarios/ModalApontamentoHoras';
import { Employee } from '../../models/employeeType';

interface IEvent {
    eventId: number,
    name: string,
    initialDate: Date | undefined,
    finalDate: Date | undefined,
}

const schema = z.object({
    name: z.string().nullable(),
    CPF: z.string().nullable(),
    company: z.string().default(''),
});

type SearchType = z.infer<typeof schema>

type TableActionProps = {
    employee: IEmployee
}

const Funcionarios: FC = () => {

    const {
        register,
        handleSubmit,
        setValue,
        getValues
    } = useForm<SearchType>({
        resolver: zodResolver(schema)
    })

    const { hasRole } = useContext(AuthContext);
    const { formatMaskRgOrCpf } = useFormat();

    //SERVIÇOS EXTERNOS
    const service = new EventService();
    const companyService = new CompanyService();

    const navigate = useNavigate();

    // MANIPULAÇÃO DOS DADOS
    const [funcionarioEdit, setFuncionarioEdit] = useState<IEmployee | undefined>(undefined);
    const [funcionarioPrint, setFuncionarioPrint] = useState<IEmployee | undefined>(undefined);
    const [companys, setCompanys] = useState<Company[]>([]);
    const [evento, setEvento] = useState<IEvent>();


    // CONTROLE DOS DIALOGS
    const [reload, setReload] = useState(false);
    const [showPrint, setShowPrint] = useState(false);
    const [funcionarios, setFuncionarios] = useState<IEmployee[]>([]);
    const [showCriarFuncionario, setShowCriarFuncionario] = useState(false);
    const [showModalApontamento, setShowModalApontamentos] = useState(false);
    const [showImportarPlanilha, setShowImportarPlanilha] = useState(false);
    const [showGerarCodeNumbers, setShowGerarCodeNumbers] = useState(false);
    const [loading, setLoading] = useState(false);

    const { state } = useLocation();

    const reloadPage = () => {
        setFuncionarioEdit(undefined);
        fetchData();
    }

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [reload]);

    const fetchData = async () => {
        const { eventId } = state;

        var filter: IFilterEmployee = {
            eventId: eventId,
            fullName: '',
            cpf: '',
            companyId: 0
        }

        Promise.all([
            service.getEvent(eventId),
            service.getEmployees(filter),
            companyService.getEventCompanysAsync(eventId)
        ]).then(([event, employees, companys]) => {
            setEvento(event);
            setFuncionarios(employees);
            setCompanys(companys);
            setLoading(false);
            setReload(false);
        });
    }

    const handleFind = async (data: SearchType) => {

        let filtro: IFilterEmployee = {
            eventId: evento!.eventId,
            fullName: data.name ?? '',
            cpf: data.CPF ?? '',
            companyId: getCompanyId(data.company ?? '')
        };

        var employees = await service.getEmployees(filtro);
        setFuncionarios(employees);
        setValue('company', '');
    }

    const handleDelete = async (row: any) => {
        if (row.employeeId > 0) {
            await service.deleteEmployee(row.employeeId);
            setReload(true);
        }
    }

    const handleEdit = async (row: IEmployee) => {
        if (row.employeeId > 0) {
            var funcionario = await service.getEmployee(row.employeeId);
            setFuncionarioEdit(funcionario);
            setShowCriarFuncionario(true);
        }
    }

    const handlePrint = async (row: IEmployee) => {
        var funcionario = await service.getEmployee(row.employeeId);

        if (funcionario) {
            setFuncionarioPrint(funcionario);
            setShowPrint(true);
        } else {
            toast.error("Não foi possivel localizar o funcionario por favor tente novamente.");
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'photoUri', headerName: 'Foto', headerAlign: 'center', align: 'center',
            renderCell: (params: any) => {
                if (params.value.length > 0) {
                    return (
                        <Avatar
                            alt={params.row.name}
                            src={`${params.value}?v=${Math.random()}`}>
                        </Avatar>
                    )
                } else {
                    return (
                        <Avatar alt={params.row.name}>
                        </Avatar>
                    )
                }
            }
        },
        // {
        //     field: 'codeNumber',
        //     headerName: 'QRCode',
        //     width: 100
        // },
        {
            field: 'fullName',
            headerName: 'Nome do Funcionario',
            flex: 2,
        },
        {
            field: 'cpf',
            headerName: 'CPF/RG',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => {
                return (<span>{formatMaskRgOrCpf(params.value)}</span>)
            },
        },
        {
            field: 'company',
            headerName: 'Fornecedor',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => {
                let company = companys.find(c => c.companyId === params.row.companyId);
                return (<span>{company?.name}</span>)
            },
        },
        {
            field: 'staff',
            headerName: 'Setor',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{params.value}</span>
        },
        {
            field: 'function',
            headerName: 'Função',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{params.value}</span>
        },
        {
            field: 'actions',
            headerName: 'Ações',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            filterable: false,
            renderCell: (params: any) => <TableActionsComponent employee={params.row as IEmployee} />
        }
    ];



    const handleCreate = async () => {
        setFuncionarioEdit(undefined);
        setShowCriarFuncionario(true);
    }

    const getCompanyId = (nameCompany: string): number => {
        if (nameCompany === undefined || nameCompany == null)
            return 0;

        let company = companys
            .find(c => c.name.toLowerCase().trim() === nameCompany.toLowerCase().trim());

        return company?.companyId ?? 0;
    }

    const handleTimeSheet = (employee: IEmployee) => {
        setFuncionarioPrint(employee);
        setShowModalApontamentos(true);
    }

    return (
        <>
            <div className='p-0' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={styles.panel} >
                    <div className='row m-0 my-3 px-2' style={{ background: 'white', border: '0px', borderRadius: '10px' }}>
                        <HeaderComponent />
                        <FormEmployee />
                        <div className='d-flex py-2 gap-2' style={{ color: 'white', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <AcoesComponent />
                        </div>
                    </div>
                    <TableEmployeeComponent />
                </div>
            </div>

            <Dialogs />
        </>
    )

    function TableEmployeeComponent() {
        return (
            <React.Fragment>
                <div className="row my-1 mx-2">
                    <div style={{ display: 'flex', height: '400px', background: 'white', borderRadius: '10px' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid rows={funcionarios}
                                columns={columns}
                                loading={loading}
                                getRowId={(row) => row.employeeId}
                                style={{ border: '0px' }} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }



    function TableActionsComponent({ employee }: TableActionProps) {
        return (
            <React.Fragment>
                <EnableComponent role={RoleEnum.RealizarCheckinCheckoutFuncionario}>
                    <Tooltip placement="top" title="Ver Apontamento de Horas">
                        <IconButton style={{ color: '#003958' }} size="large" onClick={() => handleTimeSheet(employee)}>
                            <TbClockPlus />
                        </IconButton>
                    </Tooltip>
                </EnableComponent>

                <EnableComponent role={RoleEnum.RealizarCheckinCheckoutFuncionario}>
                    <Tooltip placement="top" title="Registrar Ponto de Entrada/Saida">
                        <IconButton style={{ color: '#003958' }} size="large" onClick={() => handlePrint(employee)}>
                            <AiFillPrinter />
                        </IconButton>
                    </Tooltip>
                </EnableComponent>

                <EnableComponent role={RoleEnum.CadastrarEditarFuncionario}>
                    <Tooltip placement="top" title="Editar Funcionário">
                        <IconButton style={{ color: '#003958' }} size="large" onClick={() => handleEdit(employee)}>
                            <BsPencilSquare />
                        </IconButton>
                    </Tooltip>
                </EnableComponent>

                <EnableComponent role={RoleEnum.ExcluirFuncionario}>
                    <Tooltip placement="top" title="Excluir Funcionário">
                        <IconButton style={{ color: '#003958' }} size="large" onClick={() => handleDelete(employee)}>
                            <BsFillTrashFill />
                        </IconButton>
                    </Tooltip>
                </EnableComponent>
            </React.Fragment>
        )
    }

    function HeaderComponent() {
        return <div className='row my-3'>
            <div className="col-1">
                <MyButton startIcon={<IoMdArrowBack />}
                    onClick={() => navigate('/events')}>
                    VOLTAR
                </MyButton>
            </div>
            <div className="col-10">
                <Header text={`${evento?.name == undefined ? '' : evento?.name}`} />
            </div>
        </div>;
    }

    function FormEmployee() {
        return (
            <React.Fragment>
                <Form onSubmit={handleSubmit(handleFind)} className='row' style={{ border: '0px' }}>
                    <div className="col-md-6 flex justify-center items-center gap-3">
                        <TextField label='Nome do Funcionario'
                            {...register('name')}
                            className='w-100'
                            disabled={!hasRole(RoleEnum.ConsultarFuncionario)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSubmit(handleFind)();
                                }
                            }} />

                        <TextField label='CPF/RG'
                            className='w-100'
                            {...register('CPF')}
                            disabled={!hasRole(RoleEnum.ConsultarFuncionario)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSubmit(handleFind)();
                                }
                            }} />

                        <Autocomplete
                            key={new Date().getTime()}
                            disablePortal
                            className='w-full'
                            value={getValues('company')}
                            onChange={(event, value: any) => {
                                setValue('company', value);
                            }}
                            options={companys.map((option: Company) => option.name)}
                            renderInput={(params: any) => <TextField {...params} label="Fornecedores" />}
                        />
                    </div>

                    <div className='col-md-6'>
                        <div className="col-4">
                            <MyButton endIcon={<BsSearch />} type='submit' disabled={!hasRole(RoleEnum.ConsultarFuncionario)}>
                                Pesquisar
                            </MyButton>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }

    function Dialogs() {
        return (
            <React.Fragment>
                {evento && (
                    <ModalCriarFuncionario
                        reloadPage={reloadPage}
                        show={showCriarFuncionario}
                        setShow={setShowCriarFuncionario}
                        eventId={evento!.eventId}
                        funcionario={funcionarioEdit}
                        companys={companys}
                    />
                )}

                {funcionarioPrint && <ModalApontamentoHoras
                    employeeId={funcionarioPrint.employeeId}
                    employeeName={funcionarioPrint.fullName}
                    show={showModalApontamento}
                    setShow={setShowModalApontamentos}
                />}

                {evento && (
                    <ModalImportarPlanilhaExcel
                        eventId={evento!.eventId}
                        reloadPage={reloadPage}
                        show={showImportarPlanilha}
                        setShow={setShowImportarPlanilha}
                    />
                )}

                {evento && (
                    <ModalPrintFuncionario
                        funcionario={funcionarioPrint}
                        show={showPrint}
                        setShow={setShowPrint}
                        reloadPage={reloadPage}
                        companys={companys}
                    />
                )}

                {evento && (
                    <ModalGerarCodeNumber
                        funcionarios={funcionarios}
                        show={showGerarCodeNumbers}
                        setShow={setShowGerarCodeNumbers}
                        reloadPage={reloadPage}
                        eventId={evento.eventId}
                    />
                )}
            </React.Fragment>
        )
    }

    function AcoesComponent() {
        return (
            <React.Fragment>


                <EnableComponent role={RoleEnum.CadastrarEditarFuncionario}>
                    <MyButton endIcon={<FiUserPlus />}
                        onClick={() => handleCreate()}>
                        Criar Funcionario
                    </MyButton>
                </EnableComponent>

                <EnableComponent role={RoleEnum.CadastrarEditarFuncionario}>
                    <MyButton endIcon={<AiFillFileExcel />}
                        onClick={() => setShowImportarPlanilha(true)}>
                        Importar Funcionários
                    </MyButton>
                </EnableComponent>

                <EnableComponent role={RoleEnum.CadastrarEditarFuncionario}>
                    <MyButton endIcon={<BsQrCode />}
                        onClick={() => setShowGerarCodeNumbers(true)}>
                        Gerar Numeros de Série
                    </MyButton>
                </EnableComponent>

                <MyButton endIcon={<HiOutlineDocumentReport />}
                    onClick={() => navigate(`/reports`, { state: { idEvent: evento?.eventId } })}>
                    Relatório TimeSheet
                </MyButton>
            </React.Fragment>
        )
    }
}

export default Funcionarios;
