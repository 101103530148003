import { EventService } from "../../Services/EventService";
import { Modal } from 'react-bootstrap';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FaCloudUploadAlt } from "react-icons/fa";
import '../../app.css'
import { MyButton } from "../MyButton";

interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    events: IEvent[];

}

interface IEvent {
    eventId: number;
    nameEvent: string;
    totalEmployees: number;
    totalTimeSheets: number;
}

export default function ModalDisponibilizarEventos(props: IProps) {
    const service = new EventService();

    const columns: GridColDef[] = [
        {
            field: 'nameEvent',
            headerName: 'Nome do Evento',
            headerClassName:'text-table',
            flex: 1
        },
        {
            field: 'totalEmployees',
            headerName: 'Total Funcionários',
            align: 'center',
            headerClassName: 'text-table',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'totalTimeSheets',
            headerClassName: 'text-table',
            headerName: 'Total Folhas de Ponto',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        }
    ];

    const handleUpCloud = async () => {
        await service.upOnCloud(() => { });
    }

    return (
        <Modal show={props.show} size='lg'>
            <Modal.Header closeButton onHide={() => props.setShow(false)} style={{ textAlign: 'center' }}>
                <Modal.Title style={{ width: '100%' }}>Eventos que Serão Disponibilizados Online</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.events && props.events.length > 0 ? (
                    <div id="tabela">
                        <DataGrid
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            hideFooterPagination
                            rows={props.events}
                            columns={columns}
                            getRowId={(row) => row.eventId}
                            style={{
                                border: '0px',
                            }}
                        />
                    </div>
                ) : (
                    <div className='text-center'>
                        <h4>Não há eventos para serem disponibilizados online</h4>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer className='d-flex justify-content-center'>
                <MyButton endIcon={<FaCloudUploadAlt />}
                    onClick={() => handleUpCloud()}
                    disabled={props.events === undefined || props.events.length === 0}
                >
                    Disponibilizar Online
                </MyButton>
            </Modal.Footer>

        </Modal >
    )
}