import { FC, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RxLapTimer } from 'react-icons/rx';

import { IEmployee } from '../../Interfaces/interfaces';
import { PulseiraComponent } from '../PulseiraComponent';
import { EventService } from '../../Services/EventService';
import { MyButton } from '../MyButton';
import { z } from 'zod';
import { set, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '../ErrorMessage';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { CompanyService } from '../../Services/CompanyService';
import { Company } from '../../models/eventType';



interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    reloadPage: Function;
    funcionario: IEmployee | undefined;
    companys: Company[];
}

const schema = z.object({
    choice: z.string().nullable()
})

type FormData = z.infer<typeof schema>

const ModalPrintFuncionario: FC<IProps> = (props) => {

    const service = new EventService();

    const [company, setCompany] = useState<Company>();
    const [confirmModal, setConfirmModal] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: {
            errors
        },
        setValue
    } = useForm<FormData>({
        resolver: zodResolver(schema)
    });

    useEffect(() => {
        let company = props.companys.find(c => c.companyId === props.funcionario?.companyId);
        setCompany(company);
    }, [props.funcionario?.employeeId]);

    const handleClose = () => {
        setConfirmModal(false);
        props.setShow(false);
    };

    const handle = async (data: FormData) => {
        if (data.choice === null) {
            toast.error('Selecione se e Checkin ou Checkout por favor')
            return;
        }
        if (!props.funcionario) {
            toast.error('Tente novamente por favor !')
            return;
        }

        reset();
        setValue('choice', null);
        await service.createTimeSheet(props.funcionario, data.choice, handleClose);
    }

    return (
        <>
            <Modal show={props.show}>
                <Modal.Header closeButton onHide={() => {
                    setConfirmModal(true);
                }} style={{ textAlign: 'center' }}>
                    <Modal.Title style={{ width: '100%' }}>
                        <div style={{ margin: '0px', flexDirection: 'column' }}>
                            <p>Impressão da Pulseira</p>
                            <p style={{ margin: '0px', fontSize: '15px' }}>{props.funcionario?.fullName}</p>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {props.funcionario && (
                        <PulseiraComponent
                            codeNumber={props.funcionario.codeNumber}
                            fullName={props.funcionario.fullName}
                            cpf={props.funcionario.cpf}
                            company={company?.name || ''}
                            staff={props.funcionario.staff}
                            function={props.funcionario.function}
                        />
                    )}
                </Modal.Body>

                <Modal.Footer className='d-flex justify-content-center'>

                    <form onSubmit={handleSubmit(handle)} style={{ border: '0' }}>
                        <div className='row flex justify-center py-2'>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                style={{
                                    justifyContent: 'center'
                                }}
                            >
                                <FormControlLabel
                                    {...register('choice')}
                                    value="checkin" control={<Radio />}
                                    label="Checkin"
                                />
                                <FormControlLabel
                                    label="Checkout"
                                    {...register('choice')}
                                    value="checkout"
                                    control={<Radio />}
                                />
                            </RadioGroup>

                            {errors.choice && <ErrorMessage errorMessage={errors.choice?.message} />}
                        </div>
                        <MyButton endIcon={<RxLapTimer />} type='submit'>
                            Validar Manualmente
                        </MyButton>
                    </form>
                </Modal.Footer>

            </Modal>

            <Modal show={confirmModal} onHide={() => setConfirmModal(!confirmModal)}>
                <Modal.Body className='text-center my-3 text-xl'>
                    Tem certeza que deseja sair ?
                </Modal.Body>
                <footer className='row flex'>
                    <div className='col-md-6 p-3'>
                        <MyButton onClick={() => {
                            handleClose();
                        }}>
                            Sair
                        </MyButton>
                    </div>
                    <div className='col-md-6 p-3'>
                        <Button onClick={() => {
                            setConfirmModal(false);
                        }} variant='outlined' className='w-100'>
                            Voltar ao Checkin
                        </Button>
                    </div>
                </footer>
            </Modal>
        </>
    )
}

export default ModalPrintFuncionario;