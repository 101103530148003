import { ReactNode, useContext } from "react";
import AuthContext from "../Context/auth";
import { RoleEnum } from "../models/enumType";



type Props = {
    children: ReactNode;
    role: RoleEnum;
}

export function EnableComponent(props: Props) {
    const { hasRole } = useContext(AuthContext);
    
    return (
        <>
            { hasRole(props.role) && <>{props.children}</> }
        </>
    )


}