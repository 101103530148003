import { FC, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { HiOutlineSave } from 'react-icons/hi';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField
} from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { IEmployee } from '../../Interfaces/interfaces';
import { EventService } from '../../Services/EventService';
import { MyButton } from '../MyButton';
import { ErrorMessage } from '../ErrorMessage';
import { CompanyService } from '../../Services/CompanyService';
import { Company } from '../../models/eventType';


interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    reloadPage: Function;
    eventId: number;
    funcionario: IEmployee | undefined;
    companys: Company[]
}


const schema = z.object({
    employeeId: z.number(),
    eventId: z.number(),
    company: z.string().min(1, 'A Empresa e obrigatória.'),
    companyId: z.number().default(0),
    codeNumber: z.number(),
    cpf: z.string().min(1, 'O CPF/RG e obrigatório.'),
    staff: z.string().min(1, 'O Setor e obrigatória.'),
    fullName: z.string().min(5, 'O nome deve ter no minimo 5 letras.'),
    email: z.string().nullable(),
    function: z.string(),
    createdAtEvent: z.coerce.boolean(),
    checkin: z.coerce.boolean()
});

type FormData = z.infer<typeof schema>

const ModalCriarFuncionario: FC<IProps> = (props) => {
    const service = new EventService();
    const [,setRandom] = useState<number>(0);
    const [selectFornecedor, setSelectForncecedor] = useState<string>('');

    const getCompanyId = (nameCompany: string): number => {
        if (nameCompany === undefined || nameCompany == null)
            return 0;

        let company = props.companys
            .find(c => c.name.toLowerCase().trim() === nameCompany.toLowerCase().trim());

        return company?.companyId ?? 0;
    }

    const getCompanyName = (companyId: number): string => {
        if (companyId === 0)
            return '';

        let company = props.companys
            .find(c => c.companyId == companyId);

        return company?.name ?? '';
    }

    const {
        register,
        handleSubmit,
        formState: {
            isLoading,
            errors
        },
        getValues,
        setValue,
        reset
    } = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues: {
            eventId: props.eventId,
            createdAtEvent: (props.funcionario === undefined ? true : false),
            company: (props.funcionario === undefined ? '' : getCompanyName(props.funcionario.companyId)),
            employeeId: (props.funcionario === undefined ? 0 : props.funcionario.employeeId),
            codeNumber: (props.funcionario == undefined ? 0 : props.funcionario.codeNumber),
        }
    });

    useEffect(() => {
        if (props.funcionario && props.funcionario?.employeeId > 0) {
            setSelectForncecedor(getCompanyName(props.funcionario.companyId));
            setValue("employeeId", props.funcionario.employeeId);
            setValue("company", getCompanyName(props.funcionario.companyId));
            setValue("companyId", props.funcionario.companyId);
            setValue("codeNumber", props.funcionario.codeNumber);
            setValue("cpf", props.funcionario.cpf);
            setValue("staff", props.funcionario.staff);
            setValue("fullName", props.funcionario.fullName);
            setValue("email", props.funcionario.email);
            setValue("codeNumber", props.funcionario.codeNumber);
            setValue("function", props.funcionario.function);
            setRandom(Math.random());
        }
    }, [props.funcionario]);

  

    const handleClose = () => {
        setSelectForncecedor('');
        reset();
        props.reloadPage();
        props.setShow(!props.show);
    };

    const handle = async (form: FormData) => {

        form.eventId = props.eventId;
        form.createdAtEvent = form.employeeId === 0;
        form.companyId = getCompanyId(form.company);

        if (form.employeeId === 0)
            await service.addEmployee(form);
        else {
            await service.updateEmployee(form);
        }

        handleClose();
    }



    return (
        <>
            <Modal show={props.show}>
                <Modal.Header closeButton onHide={handleClose} style={{ textAlign: 'center' }}>
                    <Modal.Title style={{ width: '100%' }}>
                        {props.funcionario && props.funcionario?.employeeId > 0 ? 'Editar' : 'Criar'} Funcionário
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='d-flex justify-content-center'>
                    <Form onSubmit={handleSubmit(handle)} style={{ border: '0px' }}>
                        <div className='row m-0 gap-3 flex'>
                            <TextField label='Nome *'
                                {...register('fullName')}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(handle)();
                                    }
                                }} />
                            {errors.fullName && <ErrorMessage errorMessage={errors.fullName.message} />}

                            <TextField label='CPF/RG *'
                                {...register('cpf')}
                                name='cpf'
                                inputProps={{ maxLength: "11" }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(handle)();
                                    }
                                }}
                            />
                            {errors.cpf && <ErrorMessage errorMessage={errors.cpf.message} />}


                            <FormControl fullWidth>
                                <Autocomplete
                                    key={new Date().getTime()}
                                    disablePortal
                                    className='w-full'
                                    value={selectFornecedor}
                                    onChange={(event, value: any) => {
                                        setValue('company', value);
                                        setSelectForncecedor(value);
                                    }}
                                    options={props.companys.map((option: Company) => option.name)}
                                    renderInput={(params: any) => <TextField {...params} label="Fornecedores *" />}
                                />
                            </FormControl>
                            {errors.company && <ErrorMessage errorMessage={errors.company.message} />}

                            <TextField label='Setor *'
                                {...register('staff')}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(handle)();
                                    }
                                }} />
                            {errors.staff && <ErrorMessage errorMessage={errors.staff.message} />}

                            <TextField label='Função'
                                {...register('function')}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(handle)();
                                    }
                                }} />

                            <TextField label='Email'
                                {...register('email')}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(handle)();
                                    }
                                }} />



                            {props.funcionario == undefined ? (
                                <Form.Group className='mb-2'>
                                    <FormControlLabel
                                        label="Realizar o checkin do funcionario"
                                        control={
                                            <Checkbox
                                                style={{ color: '#212529' }}
                                                size="medium"
                                                {...register('checkin')} />
                                        } />
                                </Form.Group>
                            ) : (<div className='mb-2'></div>)}
                        </div>
                        <div className="row">
                            <MyButton endIcon={<HiOutlineSave />}
                                disabled={isLoading} type='submit'>
                                {props.funcionario && props.funcionario?.employeeId > 0 ? 'Editar Funcionario' : 'Cadastrar Funcionario'}
                            </MyButton>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCriarFuncionario;