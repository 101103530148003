import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { MyButton } from "../MyButton";
import { UserService } from "../../Services/UserService";

interface IProps {
    closeDialogDelete: () => void;
    open: boolean;
    userId: number;
    reloadPage: () => void;
}


export function DeleteUserDialog(props: IProps) {

    const service = new UserService();

    const handleDelete = async () => {
        await service.deleteUserAsync(props.userId);
        props.closeDialogDelete();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.closeDialogDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Exclusão de Usuário"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Tem certeza que deseja excluir este usuário ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MyButton onClick={props.closeDialogDelete}>
                    Cancelar
                </MyButton>
                <MyButton onClick={handleDelete}>
                    Excluir Usuario 
                </MyButton>
            </DialogActions>
        </Dialog>
    )
}