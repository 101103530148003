import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, CircularProgress, Paper, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { VscExport } from "react-icons/vsc";
import { LuRefreshCw } from "react-icons/lu";
import { BsSearch } from "react-icons/bs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { IoMdSettings } from "react-icons/io";

import { EventService } from "../../Services/EventService";
import { Event } from '../../models/eventType';
import { MyButton } from "../../component/MyButton";
import { IntegrationService } from "../../Services/IntegrationService";
import { ReportService } from "../../Services/ReportService";
import { ReportEvent } from "../../models/ReportEventType";
import { FormTable } from "./FormTable";
import { ConfigurationEndpointsComponent } from "../../component/Integration/ConfigurationEndpointsComponent";

const steps = ['Buscando Fornecedores', 'Validando Integração', 'Validação Concluida'];

var reportService = new ReportService();
var eventService = new EventService();
var integrationService = new IntegrationService();

export function IntegrationDimep() {

    const [events, setEvents] = useState<Event[]>([]);
    const [numberRandom, setNumberRandom] = useState<number>(0);
    const [stepIndex, setStepIndex] = useState<number>(-1);
    const [search, setSearch] = useState<Event>();
    const [hasError, setHasError] = useState<boolean>(false);
    const [report, setReport] = useState<ReportEvent>();
    const [loading, setLoading] = useState(false);
    const [showConfigAPI, setShowConfigAPI] = useState(false);

    useEffect(() => {
        eventService.getUserEventsAsync({ name: '' })
            .then((response) => {
                setEvents(response);
            })
    }, []);

    const fetchData = async (eventId: number) => {
        await reportService.getEventReport(eventId)
            .then((response: any) => {
                setReport(response);
            })
    }

    const handleValidate = async () => {
        if (!search || search.eventId <= 0) {
            toast.warn("Selecione um Evento");
        } else {
            setHasError(false)
            setStepIndex(0)
            await fetchData(search.eventId);

            setStepIndex(1);
            let result = await integrationService.companyIsValidForIntegration(search.eventId);

            setStepIndex(2);
            if (result.data) {
                setStepIndex(3);
            } else {
                setHasError(true);
            }
        }
    }

    function Filter() {
        return (
            <Paper elevation={10} id="filter" className='grid grid-cols-3 gap-4 mt-4 px-10 py-4 grow'>
                <Autocomplete
                    key={numberRandom}
                    value={search?.name}
                    disablePortal
                    id="combo-box-demo"
                    options={events.map((p: Event) => `${p.name}`)}
                    fullWidth
                    onInputChange={(event: any, value: any) => {
                        let _event = events.find(p => p.name === value);
                        if (_event != null)
                            setSearch(_event);
                    }}
                    renderInput={(params) => <TextField {...params} label="Escolha o Evento Para Exportação" />}
                />

                <MyButton endIcon={<BsSearch />} type='submit' className='h-100' onClick={() => {
                    setNumberRandom(numberRandom + 1);
                    if (search) {
                        fetchData(search.eventId);
                    } else {
                        toast.warn("Selecione um Evento");
                    }
                }}>
                    Pesquisar
                </MyButton>

                <MyButton endIcon={<IoMdSettings />} onClick={() => setShowConfigAPI(true)}>
                    Configurar API
                </MyButton>
            </Paper>
        );
    }

    function SteperComponent() {
        return (
            <Box sx={{ width: '100%', marginTop: '30px' }}>
                <Paper elevation={10} className="p-4">
                    <Stepper activeStep={stepIndex}>
                        {steps.map((label, index) => {
                            const labelProps: {
                                optional?: React.ReactNode;
                                error?: boolean;
                            } = {
                                error: hasError
                            };

                            if (labelProps.error) {
                                return (
                                    <Step key={label} className="">
                                        <StepLabel {...labelProps}>Validação Falhou</StepLabel>
                                    </Step>
                                )
                            }

                            return (
                                <Step key={label} className="">
                                    <StepLabel {...labelProps}>{hasError ? "" : label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <MyButton fullWidth className="mt-4" onClick={handleValidate}>
                        INICIAR PROCESSO DE VALIDAÇÃO <LuRefreshCw className="text-2xl ml-4" />
                    </MyButton>
                </Paper>
            </Box>
        )
    }

    const columns: GridColDef[] = useMemo(() => {
        return [
            { field: 'nameCompany', headerName: 'Fornecedor', flex: 1 },
            {
                field: 'qtdEmployees', headerName: 'Qtd de Funcionários', flex: 1, align: 'center', headerAlign: 'center'
            },
            {
                field: 'qtdStaff', headerName: 'Qtd de Setores', flex: 1, align: 'center', headerAlign: 'center'
            },
            {
                field: 'acoes',
                headerName: 'Ações',
                headerAlign: 'center',
                flex: 2,
                renderCell: (params) => (
                    <FormTable
                        eventId={search?.eventId ?? 0}
                        companyId={params.row.companyId}
                        fornecedorDIMEP={params.row.fornecedorDIMEP}
                        accessProfileDIMEP={params.row.accessProfileDIMEP}
                        fetchData={fetchData}
                    />)
            },
        ];
    }, [search?.eventId])

    const handleExportar = async () => {
        setLoading(true);
        if (search) {
            toast.success('Exportação iniciada aguarde a sua conclusão');
            var response = await integrationService.exportData(search.eventId)

        } else {
            toast.warn('Selecione um Evento');
        }
        setLoading(false);
    }

    const TabelaFornecedores = () => {
        return (
            <>
                <div className="row w-full m-0">
                    <Paper elevation={10} className="p-0" style={{ height: '400px', marginTop: '30px' }}>
                        {search && search.eventId > 0 && report != null ? (
                            <DataGrid
                                className='w-auto'
                                rows={report.companys}
                                getRowId={(row) => row.companyId}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 10
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                            />
                        ) : (
                            <div className='flex justify-center items-center text-3xl mt-10 grow h-4/6'>
                                Selecione Um Evento Para Exportação
                            </div>
                        )}
                    </Paper>
                    <div className='flex'>
                        <Button className="mt-4 col-md-6 py-2" style={{ backgroundColor: '#003958', opacity: hasError ? 0.6 : 1 }}
                            onClick={handleExportar} disabled={hasError} fullWidth variant="contained">
                            {loading ? <CircularProgress /> : <>EXPORTAR <VscExport className="text-2xl ml-4" /></>}
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='flex'>
                <span className='flex-none w-32 h-32' />
                <Filter />
                <span className='flex-none w-32 h-32' />
            </div>



            <div className='flex'>
                <span className='flex-none w-32 h-32' />
                <SteperComponent />
                <span className='flex-none w-32 h-32' />
            </div>

            <div className='flex'>
                <span className='flex-none w-32 h-32' />
                <TabelaFornecedores />
                <span className='flex-none w-32 h-32' />
            </div>


            <ConfigurationEndpointsComponent open={showConfigAPI} onClose={() => setShowConfigAPI(false)} />
        </>
    )
}