import { ServiceBase } from "./ServiceBase";
import api from "./api";
import { Response } from "../models/responseType";
import { Company } from "../models/eventType";
import { EnumQuantity } from "../models/enumType";

export class CompanyService extends ServiceBase {
    updateCompany(data: Company) {
        return new Promise<Response<Company>>(async (resolve, reject) => {
            await api.put(`company`, data)
                .then((response: any) => {
                    let result: Response<Company> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    createCompany(data: Company) {
        return new Promise<Response<Company>>(async (resolve, reject) => {
            await api.post(`company`, data)
                .then((response: any) => {
                    let result: Response<Company> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    deleteCompany(companyIdDelete: undefined) {
        return new Promise<Company>(async (resolve, reject) => {
            await api.delete(`company/${companyIdDelete}`)
                .then((response: any) => {
                    let result: Response<Company> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    getAllCompanys = async () => {
        return new Promise<Company[]>(async (resolve, reject) => {
            await api.get(`company`)
                .then((response: any) => {
                    let result: Response<Company[]> = response.data;
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    getAllCompanysActive = async (active: boolean) => {
        return new Promise<Company[]>(async (resolve, reject) => {
            await api.get(`company/active/${active}`)
                .then((response: any) => {
                    let result: Response<Company[]> = response.data;
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    disableCompany(companyId: number) {
        return new Promise<Company>(async (resolve, reject) => {
            await api.put(`company/disableCompany/${companyId}`)
                .then((response: any) => {
                    let result: Response<Company> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    registerCompanyInEvent = async (request: any) => {
        return new Promise<Company[]>(async (resolve, reject) => {
            await api.post(`company/registerEvent`, request)
                .then((response: any) => {
                    let result: Response<Company[]> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    removeCompany(eventId: number, companyId: number) {
        return new Promise<Company[]>(async (resolve, reject) => {
            await api.delete(`company/removeCompany/${eventId}/${companyId}`)
                .then((response: any) => {
                    let result: Response<Company[]> = response.data;
                    this.handleAfterRequest(result);
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }

    changeQtdEmployee(request: { eventId: number; companyId: number; option: EnumQuantity; }) {
        return new Promise<Company[]>(async (resolve, reject) => {
            await api.put(`company/ChangeQtdEmployees`, request)
                .then((response: any) => {
                    let result: Response<Company[]> = response.data;
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });
        });
    }

    getEventCompanysAsync = async (eventId: number) => {
        return new Promise<Company[]>(async (resolve, reject) => {
            await api.get(`company/ToList/${eventId}`)
                .then((response: any) => {
                    let result: Response<Company[]> = response.data;
                    resolve(result.data);
                }).catch(response => {
                    this.handleError();
                });
        });
    }

    importarPlanilhaExcel(eventId: number, jsonData: Company[]) {
        return new Promise<Response<string[]>>(async (resolve, reject) => {
            await api.post(`company/ImportarExcel`, {
                eventId: eventId,
                companys: jsonData
            }).then((response: any) => {
                let result: Response<string[]> = response.data;
                resolve(result);
            }).catch(response => {
                this.handleError();
            });
        });
    }

}