import { ServiceBase } from "./ServiceBase";
import api from "./api";
import { Response } from "../models/responseType";
import { TimeSheet } from "../models/employeeType";

export class TimeSheetService extends ServiceBase {
    getEmployeeTimeSheetAsync(employeeId: number) {
        return new Promise<Response<TimeSheet[]>>(async (resolve, reject) => {
            await api.get(`eventTimeSheet/get/${employeeId}`)
                .then((response: any) => {
                    let result: Response<TimeSheet[]> = response.data;
                    resolve(result);
                }).catch(response => {
                    this.handleError();
                });;
        });
    }
}