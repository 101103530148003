
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import { ButtonProps } from '@mui/material/Button';

interface IProps {
    children: string;
    size?: 'small' | 'medium' | 'large';
    keyPress?: string;
    keyFunction?: Function;
    className?: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    style?: any;
    onClick?: any;
    disabled?: boolean;
    type?: string
}

export function MyButton(props: ButtonProps) {
    let cssDefault = {
        backgroundColor: "#003958",
        color: 'white !important',
        cursor: 'pointer !important',
        width: '100%'
    };

    return (
        <Button
            {...props}
            variant="contained"
            type={props.type === undefined ? 'button' : 'submit'}
            size={props.size === undefined ? 'medium' : props.size}
            onClick={props.onClick}
            className={props.className === undefined ? '' : props.className}
            endIcon={props.endIcon === undefined ? (<></>) : props.endIcon}
            style={props.style == undefined ? cssDefault : props.style}
            disabled={props.disabled === undefined ? false : props.disabled}
            startIcon={props.startIcon === undefined ? (<></>) : props.startIcon}
        >
            {props.children}
        </Button>
    )
}