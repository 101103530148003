import { Skeleton } from "@mui/material";


export interface IProps {
}

const Loading: React.FC<IProps> = (props) => {
    return (
        <div style={{
            height: '70vh',
            width: '100vw', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            overflowY: 'hidden'
        }}>
            <Skeleton variant="circular" width={40} height={40} animation="wave"/>
            <Skeleton variant="circular" className="mx-3" width={40} height={40} animation="wave"/>
            <Skeleton variant="circular" width={40} height={40} animation="wave"/>
        </div>
    )
}

export default Loading;