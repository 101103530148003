import { FC } from 'react';
import '../../src/app.css'

interface IPulseiraComponent {
    text: string,
}

const Header: FC<IPulseiraComponent> = (props) => {
    
    return (
        <div style={{
            width: 'auto',
            textAlign: 'center',
            paddingRight: '0px',
            fontWeight: 'bold'
        }}>
            <h4>{props.text}</h4>
        </div>
    )
}

export default Header;