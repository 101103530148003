
import readXlsxFile from 'read-excel-file'
import { ExcelData } from '../models/employeeType';
import { toast } from 'react-toastify';

export default class ExcelConverter {

    private excelFile: File;
    private REGEX_REMOVE_PONTOS_BARRAS_TRACOS = /\D/g; // Regex para selecionar tudo que não é número
    public logs: Array<string>
    public data: Array<ExcelData>;

    constructor(file: File) {
        this.logs = new Array<string>();
        this.data = new Array<ExcelData>();
        this.excelFile = file;
    }


    convertToEmployeesAsync() {
        let promise = new Promise<this>((resolve, reject) => {
            readXlsxFile(this.excelFile, {
                parseNumber(string) {
                    return string;
                },
            }).then((rows: any) => {
                if (!this.isValid(rows[0])) {
                    toast.warn('O cabeçalho da planilha esta fora do formato esperado, baixe a planilha padrão para comparar e deixar no mesmo formato')
                    return;
                } else {
                    this._convert(rows.slice(1));
                    resolve(this);
                }
            }).catch((error: any) => {
                toast.warn('Não foi possivel obter os dados da planilha');
                reject(error);
            })
        });

        return promise;
    }

    private _convert(rows: any) {
        for (let index = 0; index < rows.length; index++) {
            const value = rows[index];
            let excelData: ExcelData = {
                employeeName: value[0]?.trim() ?? '',
                cpf: value[1]?.replace(this.REGEX_REMOVE_PONTOS_BARRAS_TRACOS, '') ?? '',
                companyName: value[2] ?? '',
                staff: value[3] ?? '',
                function: value[4] ?? '',
                photoUri: '',
                email: value[5]?.trim() ?? ''
            }

            this.validateRequiredFields(excelData, index + 2);

            this.data.push(excelData);
        }
        
    }

    validateRequiredFields = (employee: ExcelData, index: number) => {
        if (this.isNullOrEmpty(employee.employeeName))
            this.logs.push(`Linha [${index}]: Esta faltando o Nome do funcionário`);

        // if (this.isNullOrEmpty(employee.cpf))
        //     this.logs.push(`Linha [${index}]: Esta faltando o CPF/RG do funcionário`);

        if (this.isNullOrEmpty(employee.companyName))
            this.logs.push(`Linha [${index}]: Esta faltando a Empresa do funcionário`);

        if (this.isNullOrEmpty(employee.staff))
            this.logs.push(`Linha [${index}]: Esta faltando o Setor do funcionário`);

        if (this.isNullOrEmpty(employee.function))
            this.logs.push(`Linha [${index}]: Esta faltando a Função do funcionário`);
    }

    isNullOrEmpty = (value: string):boolean => {
        value = value.trim();
        return value == '' || value == null || value == undefined;
    };

    isValid = (excelHeader: any): boolean => {

        var originalHeader = [
            'Nome Completo *',
            'CPF/RG *',
            'Empresa *',
            'Setor *',
            'Função *',
            'Email'
        ]

        for (let index = 0; index < originalHeader.length; index++) {

            let originalText = originalHeader[index].replaceAll('*', '').toLowerCase().trim();
            let currentlText = excelHeader[index].replaceAll('*', '').toLowerCase().trim();

            if (originalText !== currentlText) {
                return false;
            }
        }

        return true;
    }

}