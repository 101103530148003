import { FilterTableEvent } from "../models/filterType";

export class UrlParams {

    static makeParams(filter: FilterTableEvent): string {
        let urlParams = new URLSearchParams();

        if (filter.name)
            urlParams.append('name', filter.name);

        return urlParams.toString();
    }
}