export function useFormat() {


    function formatMaskCpfOrCnpj(value: string) {
        switch (value.length) {
            case 11: return formatMaskCpf(value);
            case 14: return formatMaskCNPJ(value);
            default: return value;
        }
    }

    function formatMaskCNPJ(cnpj: string) {
        if (cnpj == undefined || cnpj == null)
            return '';

        const cnpjFormatado = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        return cnpjFormatado;
    }

    function formatMaskPhone(phone: string) {
        if (phone == undefined || phone == null)
            return '';

        const phoneFormatado = phone.replace(/(\d{2})\D*(\d{4})\D*(\d{3})/, "($1)-$2-$3");
        return phoneFormatado;
    }

    function formatMaskCpf(cpf: string) {
        if (cpf == undefined || cpf == null)
            return '';

        let cpfFormatado = cpf.padStart(11, '0');

        cpfFormatado = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        return cpfFormatado;
    }

    function formatMaskRgOrCpf(value: string) {
        value = value.replace(/\D/g, '');
        if (value.length === 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
        else if (value.length === 8 || value.length === 9) {
            return value.length === 8 ?
                value.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3') :
                value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
        }
        else {
            return value;
        }

    }

    function isValidCNPJ(cnpj: string): boolean {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
            return false;
        }

        let length = cnpj.length - 2;
        let numbers = cnpj.substring(0, length);
        let digits = cnpj.substring(length);
        let sum = 0;
        let pos = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += parseInt(numbers.charAt(length - i)) * pos--;
            if (pos < 2) pos = 9;
        }

        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result != parseInt(digits.charAt(0))) return false;

        length = length + 1;
        numbers = cnpj.substring(0, length);
        sum = 0;
        pos = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += parseInt(numbers.charAt(length - i)) * pos--;
            if (pos < 2) pos = 9;
        }

        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result != parseInt(digits.charAt(1))) return false;

        return true;
    }

    function isValidCPF(cpf: string): boolean {
        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
            return false;
        }

        let sum = 0;
        let remainder;

        for (let i = 1; i <= 9; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;

        if ((remainder == 10) || (remainder == 11)) remainder = 0;
        if (remainder != parseInt(cpf.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        remainder = (sum * 10) % 11;

        if ((remainder == 10) || (remainder == 11)) remainder = 0;
        if (remainder != parseInt(cpf.substring(10, 11))) return false;

        return true;
    }

    function limparRG(rg: string): string {
        return rg.replace(/[^\dX]/g, '');
    }

    function calcularDigitoVerificadorSP(rg: string): string {
        const pesos = [2, 3, 4, 5, 6, 7, 8, 9];
        let soma = 0;

        for (let i = 0; i < 8; i++) {
            soma += parseInt(rg[i]) * pesos[i];
        }

        const resto = soma % 11;
        const digitoVerificador = 11 - resto;

        if (digitoVerificador === 10) {
            return 'X';
        } else if (digitoVerificador === 11) {
            return '0';
        } else {
            return digitoVerificador.toString();
        }
    }

    function isValidRG(rg: string, estado: string): boolean {
        const estadosComDV = ["SP", "RJ", "MG", "PR", "RS"];
        const rgLimpo = limparRG(rg);

        // Verifica se tem 9 dígitos e se não é uma sequência repetida
        if (rgLimpo.length !== 9 || /^(\d)\1+$/.test(rgLimpo)) {
            return false;
        }

        // Exemplo de verificação específica para estados com dígito verificador
        if (estadosComDV.includes(estado.toUpperCase())) {
            const digitoVerificador = calcularDigitoVerificadorSP(rgLimpo);
            return digitoVerificador === rgLimpo[8];
        }

        // Caso o estado não exija um dígito verificador específico, apenas retorna true
        return true;
    }

    return {
        formatMaskCpfOrCnpj,
        formatMaskPhone,
        formatMaskRgOrCpf,
        isValidCPF,
        isValidCNPJ
    }
}