import { useContext, useState } from "react";
import React from "react";
import { HiLogout } from "react-icons/hi";

import { Container, AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate } from "react-router-dom";
import AuthContext from "../../Context/auth";
import { EventService } from "../../Services/EventService";
import ModalDisponibilizarEventos from "./ModalDisponibilizarEventos";
import { FaUser } from "react-icons/fa";
import Logo from '../../assets/logo-weemake.png';
import LogoFundo from '../../assets/LogoGestComFundo.jpeg';

const pages = ['Cadastros', 'Relatórios'];

export default function HeaderMenu() {

    const { user, logout } = useContext(AuthContext);

    const service = new EventService();
    const [modalUpOncloud, setModalUpOncloud] = useState(false);
    const [events, setEvents] = useState([]);

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [anchorCadastros, setAnchorCadastros] = React.useState<null | HTMLElement>(null);
    const [anchorRelatorios, setAnchorRelatorios] = React.useState<null | HTMLElement>(null);
    const openCadastro = Boolean(anchorCadastros);
    const openRelatorio = Boolean(anchorRelatorios);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        switch (event.currentTarget.textContent) {
            case 'Cadastros':
                setAnchorCadastros(event.currentTarget);
                break;
            case 'Relatórios':
                setAnchorRelatorios(event.currentTarget);
                break;
            default:
                break;
        }
    };
    const handleClose = () => {
        setAnchorCadastros(null);
        setAnchorRelatorios(null);
    };


    return (
        <div>
            <AppBar position="relative" className="header">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            onClick={() => navigate('/events')}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {/* <img src={LogoFundo} style={{
                                maxWidth: '100px',
                                maxHeight: '100px',
                                width: 'auto',
                                height: 'auto',
                                marginRight: '10px'
                            }} />*/}

                            <img src={Logo} style={{
                                maxWidth: '100px',
                                maxHeight: '100px',
                                width: 'auto',
                                height: 'auto'
                            }} />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            LOGO
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <div id="cadastros">
                                <Button
                                    id="basic-button-1"
                                    aria-controls={openCadastro ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openCadastro ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="text-white"
                                >
                                    Cadastros
                                </Button>
                                <Menu
                                    anchorEl={anchorCadastros}
                                    open={openCadastro}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        navigate('/usuarios');
                                        handleClose();
                                    }}>
                                        Cadastro de Usuários
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        navigate('/companys');
                                        handleClose();
                                    }}>
                                        Cadastro de Fornecedores
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        navigate('/events');
                                        handleClose();
                                    }}>
                                        Cadastro de Eventos
                                    </MenuItem>
                                </Menu>
                            </div>

                            <div id="relatorios">
                                <Button
                                    id="basic-button-2"
                                    aria-controls={openRelatorio ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openRelatorio ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="text-white"
                                >
                                    Relatórios
                                </Button>
                                <Menu
                                    anchorEl={anchorRelatorios}
                                    open={openRelatorio}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        navigate('/event-reports')
                                        handleClose();
                                    }}>Relatório do Evento</MenuItem>
                                    {/* <MenuItem onClick={handleClose}>Relatório de Fornecedores</MenuItem> */}
                                </Menu>


                            </div>
                            <div>
                                <MenuItem onClick={() => {
                                    navigate('/integration')
                                    handleClose();
                                }}>Integração DIMEP</MenuItem>
                            </div>
                        </Box>



                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip placement="top" title="Abrir Configurações">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {user && (
                                        <p className="antialiased text-white m-0 flex justify-center align-items-center text-xl">
                                            <FaUser className="text-white mr-2" />{user?.login}
                                        </p>
                                    )}
                                </IconButton>
                            </Tooltip>
                            {user && (<Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >

                                <MenuItem key={1} onClick={logout}>
                                    Sair <HiLogout className="mx-2" />
                                </MenuItem>
                            </Menu>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>


            <ModalDisponibilizarEventos show={modalUpOncloud} setShow={setModalUpOncloud} events={events} />
        </div>
    )
}


