import { FC, useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BsSearch } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import styles from '../../Pages/Home/home.module.css';
import { EventService } from '../../Services/EventService';
import { IEmployeeTimeSheet, IFilterEmployee } from '../../Interfaces/interfaces';
import Header from '../../component/Header';
import { IoMdArrowBack } from 'react-icons/io';
import { MyButton } from '../../component/MyButton';
import { Autocomplete, TextField } from '@mui/material';
import { Company } from '../../models/eventType';
import { CompanyService } from '../../Services/CompanyService';
import { useFormat } from '../../hooks/useFormat';



interface IProps { }

interface IEvent {
    eventId: number,
    name: string,
    initialDate: Date | undefined,
    finalDate: Date | undefined,
}

const Relatorios: FC<IProps> = () => {

    const service = new EventService();
    const companyService = new CompanyService();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { formatMaskRgOrCpf } = useFormat();
    const [employeeTimeSheets, setEmployeeTimeSheets] = useState<IEmployeeTimeSheet[]>([]);
    const [reload, setReload] = useState(false);
    const [evento, setEvento] = useState<IEvent>();
    const [companys, setCompanys] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>("");


    const reloadPage = () => {
        setReload(!reload);
    }

    useEffect(() => {
        const fetchData = async () => {
            const { idEvent } = state;

            var filter: IFilterEmployee = {
                eventId: idEvent,
                fullName: '',
                cpf: '',
                companyId: 0
            }

            Promise.all([
                service.getEvent(idEvent),
                service.getEmployeeTimeSheets(filter),
                companyService.getEventCompanysAsync(idEvent)
            ]).then((response) => {
                setEvento(response[0]);
                setEmployeeTimeSheets(response[1]);
                setCompanys(response[2]);
            });
        }

        fetchData();
    }, [reload]);

    const handleFind = async () => {
        let filtro: IFilterEmployee = {
            eventId: evento!.eventId,
            fullName: formik.values.name,
            cpf: formik.values.CPF,
            companyId: formik.values.company
        };

        var employeeTimeSheets = await service.getEmployeeTimeSheets(filtro);
        setEmployeeTimeSheets(employeeTimeSheets);

        formik.setValues({ name: '', CPF: '', company: 0 });
        setSelectedCompany("");
    }

    const schema = yup.object().shape({
        name: yup.string(),
        CPF: yup.string(),
        finalDate: yup.string()
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            CPF: '',
            company: 0
        },
        validationSchema: schema,
        onSubmit: handleFind,
    });

    const columns: GridColDef[] = [
        {
            field: 'fullName',
            headerName: 'Nome do Funcionario',
            flex: 1,
        },
        {
            field: 'cpf',
            headerName: 'CPF',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{formatMaskRgOrCpf(params.value)}</span>
        },
        {
            field: 'company',
            headerName: 'Empresa',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{params.value}</span>
        },
        {
            field: 'checkin',
            headerName: 'Entrada',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{formatDate(params)}</span>
        },
        {
            field: 'checkout',
            headerName: 'Saida',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{formatDate(params)}</span>
        },
        {
            field: 'totalHours',
            headerName: 'Tempo de Trabalho',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params: any) => <span>{params.value?.split('.')[0]}</span>
        }
    ];

    const setInputValue = useCallback(
        (key: any, value: any) =>
            formik.setValues({
                ...formik.values,
                [key]: value,
            }),
        [formik]
    );

    const formatDate = (param: any) => {
        if (param.value !== null)
            return `${new Date(param.value).getDate()}/${new Date(param.value).getMonth() + 1} - ${new Date(param.value).toLocaleTimeString()}`
    }

    return (
        <>
            <div className='p-0' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={styles.panel} >
                    <div className='row m-0 my-3 px-2' style={{ border: '0px' }}>
                        <Form className='row p-0 m-0' style={{ background: 'white', border: '0px' }}>
                            <div className='row mt-3 m-0'>
                                <div className="col-1">
                                    <MyButton startIcon={<IoMdArrowBack />}
                                        onClick={() => navigate(`/event/employees`, { state: { eventId: evento?.eventId } })}>
                                        VOLTAR
                                    </MyButton>
                                </div>
                                <div className="col-10">
                                    <Header text={`Relatórios ${evento?.name === undefined ? '' : evento?.name}`} />
                                </div>
                            </div>
                            <div className='row my-3'>
                                <div className="col-md-3">
                                    <TextField label='Nome do Funcionario'
                                        name='name'
                                        fullWidth
                                        onChange={(e) => setInputValue("name", e.target.value)}
                                        value={formik.values.name}
                                        type='text'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleFind();
                                            }
                                        }} />
                                </div>
                                <div className="col-md-3">
                                    <TextField label='CPF/RG'
                                        name='cpf'
                                        fullWidth
                                        onChange={(e) => setInputValue("CPF", e.target.value)}
                                        value={formik.values.CPF}
                                        type='text'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleFind();
                                            }
                                        }} />
                                </div>
                                <div className="col-md-3">
                                    <Autocomplete
                                        key={new Date().getTime()}
                                        disablePortal
                                        className='w-full'
                                        value={selectedCompany}
                                        onChange={(event, value: any) => {
                                            let company = companys.find((c: Company) => c.name === value);
                                            if (company) {
                                                setInputValue("company", company?.companyId);
                                                setSelectedCompany(company?.name);
                                            }
                                        }}
                                        options={companys.map((option: Company) => option.name)}
                                        renderInput={(params: any) => <TextField {...params} label="Fornecedores" />}
                                    />
                                </div>

                                <div className='col-md-2' style={{ color: 'white', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0px' }}>
                                    <MyButton endIcon={<BsSearch />}
                                        className='h-full'
                                        onClick={handleFind}>
                                        Pesquisar
                                    </MyButton>
                                </div>
                            </div>


                        </Form>
                    </div>

                    <div className="row my-3 mx-2">
                        <div style={{ display: 'flex', height: '400px', background: 'white', borderRadius: '10px' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGrid rows={employeeTimeSheets}
                                    columns={columns}
                                    getRowId={(row) => row.eventUserTimeSheetId}
                                    style={{ border: '0px' }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Relatorios;
