import { Button, TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { FormDIMEP } from "../../models/dimepType";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { IntegrationService } from "../../Services/IntegrationService";
import { BiTrash } from "react-icons/bi";
import { VscSaveAll } from "react-icons/vsc";
import { FaTrashAlt } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import { BiSolidEditAlt } from "react-icons/bi";
import { useState } from "react";
import { MyButton } from "../../component/MyButton";

interface IProps {
    companyId: number;
    eventId: number;
    fornecedorDIMEP: number;
    accessProfileDIMEP: number;
    fetchData: (eventId: number) => void
}

const formSchema = z.object({
    companyId: z.coerce.number(),
    fornecedorDIMEP: z.coerce.number(),
    accessProfileDIMEP: z.coerce.number()
});

var service = new IntegrationService();

export function FormTable(props: IProps) {

    const [openEditor, setOpenEditor] = useState(props.fornecedorDIMEP <= 0 || props.accessProfileDIMEP <= 0);

    const {
        setValue,
        getValues,
        reset
    } = useForm<FormDIMEP>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            CompanyId: props.companyId,
            companyDimepId: props.fornecedorDIMEP,
            accessProfile: props.accessProfileDIMEP
        }
    });

    const handleSaveDIMEP = async () => {
        let formData: FormDIMEP = {
            CompanyId: props.companyId,
            companyDimepId: getValues('companyDimepId'),
            accessProfile: getValues('accessProfile')
        }
        await service.registerDIMEP(formData);
        props.fetchData(props.eventId);
        reset();
        setOpenEditor(false);
    }

    return (
        <div className="w-full">
            {openEditor ? (
                <div className="row">
                    <div className="col-md-4">
                        <TextField id={`${props.companyId}-id`}
                            label="Fornecedor Id"
                            variant="standard"
                            type="number"
                            defaultValue={props.fornecedorDIMEP}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValue('companyDimepId', Number(event.target.value));
                            }}
                            style={{ width: '100%' }}
                            inputProps={{ min: 0 }}
                        />
                    </div>
                    <div className="col-md-4">
                        <TextField id={`${props.companyId}-access`}
                            label="Perfil de Acesso"
                            variant="standard"
                            type="number"
                            defaultValue={props.accessProfileDIMEP}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValue('accessProfile', Number(event.target.value));
                            }}
                            style={{ width: '100%' }}
                            inputProps={{ min: 0 }}
                        />
                    </div>
                    
                    <div className="col-md-4 flex justify-center items-center">
                        <MyButton className="px-4 py-2 cursor-pointer" onClick={() => {
                            handleSaveDIMEP()
                        }} endIcon={<FaSave className="text-xl mx-2" />}>
                            Salvar 
                        </MyButton>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-4 flex items-center">
                        Fornecedor Id: {props.fornecedorDIMEP}
                    </div>
                    <div className="col-md-4 flex items-center">
                        Perfil de Acesso: {props.accessProfileDIMEP}
                    </div>
                    <div className="col-md-4 flex justify-center items-center">
                        <Button fullWidth className="px-4 py-2 cursor-pointer" variant="text" onClick={() => setOpenEditor(true)}>
                            EDITAR <BiSolidEditAlt className="text-xl mx-2" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}