import { useEffect, useState } from "react";
import { Event } from "../../models/eventType";
import { FormEventos } from "../../component/Eventos/FormEventos";
import { TabelaEventos } from "../../component/Eventos/TabelaEventos";
import { FilterTableEvent } from "../../models/filterType";
import { EventService } from "../../Services/EventService";

export function Eventos() {

    const service = new EventService();
    const [events, setEvents] = useState<Event[]>([]);

    const [filterTable, setFilterTable] = useState<FilterTableEvent>({} as FilterTableEvent);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (filter?: FilterTableEvent) => {
        const events = await service.getUserEventsAsync(filter ?? filterTable);
        setEvents(events);
    }

    const setFilter = (filter: FilterTableEvent) => {
        setFilterTable(filter);
        fetchData(filter)
    }

    return (
        <div className="row m-0">
            <div id="container-cadastro" className="col-md-3 flex justify-center mx-0">
                <FormEventos fetchData={fetchData} />
            </div>
            <div className="col-md-9">
                <TabelaEventos fetchData={fetchData} events={events} setFilter={setFilter} />
            </div>
        </div>
    )
}