import XLSX from 'sheetjs-style';
import { Company } from '../models/eventType';


export function useConvert() {

    function convertToFile(base64: string, fileName: string, mimeType = 'image/png') {
        const byteString = atob(base64.split(',')[1]);

        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeType });

        const file = new File([blob], fileName, {
            type: mimeType,
            lastModified: 1716148936369, // example timestamp
        });

        return file;
    }

    function excelToJson(excelFile: File): Promise<any> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = (e) => {
                const arrayBuffer = e.target?.result as ArrayBuffer;

                const data = new Uint8Array(arrayBuffer);
                const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
                const sheetName: string = workbook.SheetNames[0];
                const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet);
                resolve(jsonData);
            }

            reader.onerror = (e) => {
                reject(e);
            }

            reader.readAsArrayBuffer(excelFile);
        });
    }

    return {
        convertToFile,
        excelToJson
    }
}