
import { Paper } from '@mui/material';
import { FaChartLine } from "react-icons/fa";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CompanyReport, ReportEvent } from '../../models/ReportEventType';
import { MyButton } from '../MyButton';
import { DetalhesModal } from './DetalhesModal';
import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    data: ReportEvent,
    eventName: string
}

export function TabelaRelatorio(props: Props) {

    const [companySelect, setCompanySelect] = React.useState<CompanyReport>();
    const [openModal, setOpenModal] = React.useState<boolean>(false);

    const columns: GridColDef[] = [
        { field: 'nameCompany', headerName: 'Fornecedor', flex: 1 },
        {
            field: 'qtdEmployees', headerName: 'Qtd de Funcionários', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'maxAllowEmployees', headerName: 'Max Permitido', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'qtdStaff', headerName: 'Qtd de Setores', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'qtdCreateAtEvent', headerName: 'Qtd Criada no Evento', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <MyButton endIcon={<FaChartLine />} className='cursor-pointer' onClick={() => {
                            if(params.row.qtdEmployees > 0) {
                                setCompanySelect(params.row);
                                setOpenModal(true);
                            } else {
                                toast.success("Esse fornecedor não possui funcionários cadastrados para visualização")
                            }
                        }}>
                            VER MAIS DETALHES
                        </MyButton>
                    </div>
                )
            }
        },
    ];

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <>
            <div className='flex mt-3'>
                <span className='flex-none w-32 h-32' />
                <Paper elevation={10} className='grow rounded'>
                    <header className='header py-2 text-2xl'>
                        {props.eventName}
                    </header>

                    <Cards />

                    <div id='tabela' style={{ height: '400px' }}>
                        <DataGrid
                            className='w-auto'
                            rows={props.data.companys}
                            getRowId={(row) => row.companyId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        page: 0,
                                        pageSize: 10
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    </div>
                </Paper>
                <span className='flex-none w-32 h-32' />
            </div>

            {companySelect && <DetalhesModal data={companySelect} open={openModal} handleClose={handleCloseModal} />}

        </>
    )

    function Cards() {
        return (<div className="flex justify-around gap-4 py-4">
            <Paper elevation={10} className='px-24 py-4 flex flex-col'>
                <span className='text-3xl text-center'>
                    Total de Fornecedores
                </span>
                <span className='text-3xl text-center'>
                    {props.data.totalCompanys}
                </span>
            </Paper>
            <Paper elevation={10} className='px-24 py-4 flex flex-col'>
                <span className='text-3xl text-center'>
                    Total de Funcionários
                </span>
                <span className='text-3xl text-center'>
                    {props.data.totalEmployees}
                </span>
            </Paper>
        </div>);
    }
}