import { toast } from "react-toastify";

import { EmployeeReport, ReportEvent } from "../models/ReportEventType";
import { ServiceBase } from "./ServiceBase";
import api from "./api";
import { Response } from "../models/responseType";

export class ReportService extends ServiceBase {
    
    public async getEventReport(eventId: number) {
        return new Promise<ReportEvent>(async (resolve, reject) => {
            await api.get(`report/EventReport/${eventId}`)
                .then(response => {
                    let result: Response<ReportEvent> = response.data;
                    if (result.isSuccess) {
                        resolve(result.data);
                    } else {
                        toast.warn("Não foi possivel realizar a ação");
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    public async getEmployeesModalAsync(eventId: number, companyId: number) {
        return new Promise<EmployeeReport[]>(async (resolve, reject) => {
            await api.get(`${this.API_URL}Report/EmployeeReport/${eventId}/${companyId}`)
                .then(response => {
                    let result: Response<EmployeeReport[]> = response.data;
                    if (result.isSuccess) {
                        resolve(result.data);
                    } else {
                        toast.warn("Não foi possivel realizar a ação");
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }
}