// import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";

import { FormUsuarios } from "../../component/Usuarios/FormUsuarios";
import { TabelaUsuarios } from "../../component/Usuarios/TabelaUsuarios";
import { Role, User } from "../../models/userType";
import { UserService } from "../../Services/UserService";

export function Usuarios() {

    const service = new UserService();

    const [users, setUsers] = useState<User[]>([]);
    const [userSelected, setUserSelected] = useState<User | null>(null);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const users = await service.getAllUsers();
            setUsers(users);
        }

        fetchData();
    }, [reload]);

    const selectUser = (userId: number) => {
        const user = users.find(x => x.userId === userId);
        if (user)
            setUserSelected(user);
    }

    const resetParent = () => {
        setUserSelected(null);
        reloadPage();
    }

    const reloadPage = async () => {
        setReload(!reload);
    }

    return (
        <div className="row m-0">
            <div id="container-cadastro" className="col-md-3 flex justify-center mx-0">
                <FormUsuarios user={userSelected} resetParent={resetParent} />
            </div>
            <div className="col-md-9">
                <TabelaUsuarios users={users} selectUser={selectUser} userSelect={userSelected} reloadPage={reloadPage} />
            </div>
        </div>
    )
}