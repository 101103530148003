import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField/TextField";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from 'zod';
import { IoMdSettings } from "react-icons/io";
import { FaTimes } from 'react-icons/fa';
import { HiOutlineSave } from "react-icons/hi";
import { ErrorMessage } from "../ErrorMessage";
import { IntegrationService } from "../../Services/IntegrationService";


type ConfigurationEndpointsComponentProps = {
    open: boolean;
    onClose: () => void;
};

const schema = z.object({
    urlIntegracaoDimep: z.string().url({ message: 'URL inválida' }),
    loginDimep: z.string().min(1, { message: 'Login é obrigatório' }),
    passwordDimep: z.string().min(1, { message: 'Senha é obrigatória' }),
    apiKeyDimep: z.string().min(1, { message: 'Chave NAK é obrigatória' }),
});

type FormData = z.infer<typeof schema>;

export function ConfigurationEndpointsComponent(props: ConfigurationEndpointsComponentProps) {

    var service = new IntegrationService();
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm<FormData>({
        resolver: zodResolver(schema)
    });

    const onSubmit = async (data: FormData) => {
        await service.saveConfiguration(data);
        onClose();
    };

    const onClose = () => {
        clearErrors();
        reset();
        props.onClose();
    }

    return (
        <Modal show={props.open} onHide={onClose}>

            <Form onSubmit={handleSubmit(onSubmit)} className="border-0 w-full">
                <div>
                    <label className="flex items-center justify-center gap-2 mt-4 text-2xl w-full">
                        Configuração da API
                        <IoMdSettings />
                    </label>
                </div>

                <Modal.Body className="flex flex-col gap-4 px-0">
                    <div>
                        <TextField fullWidth id="url" {...register('urlIntegracaoDimep')} label="Url" />
                        {errors.urlIntegracaoDimep && <ErrorMessage errorMessage={errors.urlIntegracaoDimep.message} />}
                    </div>

                    <div>
                        <TextField fullWidth id="login" {...register('loginDimep')} label="Login" />
                        {errors.loginDimep && <ErrorMessage errorMessage={errors.loginDimep.message} />}
                    </div>

                    <div>
                        <TextField fullWidth id="senha" type="password" {...register('passwordDimep')} label="Senha" />
                        {errors.passwordDimep && <ErrorMessage errorMessage={errors.passwordDimep.message} />}
                    </div>

                    <div>
                        <TextField fullWidth id="chaveNak" {...register('apiKeyDimep')} label="Chave NAK" placeholder="NAK eyJhbG..." />
                        {errors.apiKeyDimep && <ErrorMessage errorMessage={errors.apiKeyDimep.message} />}
                    </div>

                </Modal.Body>

                <div className="flex mb-4 gap-4">
                    <Button disableElevation variant="outlined" fullWidth onClick={onClose}>
                        Fechar <FaTimes className="mx-2 text-xl" />
                    </Button>

                    <Button type="submit" variant="contained" fullWidth style={{ backgroundColor: "rgb(0, 57, 88)", color: "white" }}>
                        Salvar <HiOutlineSave className="mx-2 text-xl" />
                    </Button>
                </div>
            </Form>

        </Modal >
    );
}