import { HiOutlineSave } from "react-icons/hi";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Checkbox, FormControlLabel, Paper, TextField } from "@mui/material";
import { z } from "zod";


import { MyButton } from "../MyButton";
import { ErrorMessage } from "../ErrorMessage";
import AuthContext from "../../Context/auth";
import { Company } from "../../models/eventType";
import { RoleEnum } from "../../models/enumType";
import { CompanyService } from "../../Services/CompanyService";
import { useFormat } from "../../hooks/useFormat";
import { Response } from "../../models/responseType";


type Props = {
    fetchData: () => void;
    company: Company | null;
    setEditCompany: (company: Company) => void;
}



export function FormEmpresas(props: Props) {
    const service = new CompanyService();

    const {
        formatMaskCpfOrCnpj,
        formatMaskPhone,
        isValidCNPJ,
        isValidCPF
    } = useFormat();

    const { hasRole } = useContext(AuthContext);
    const [hasCNPJ, setHasCNPJ] = useState(false);
    const [isValidCpfCnpj, setIsValidCpfCnpj] = useState("");

    const formSchema = z.object({
        companyId: z.number().default(0),
        name: z.string().min(1, 'O nome é obrigatório').transform(p => p.replaceAll('-', ' ')),
        cnpj: z.string().optional(),
        email: z.string().min(1, 'O email é obrigatório').email('O email é inválido'),
        phone: z.string()
            .min(1, 'O telefone é obrigatório')
            .max(20, 'O telefone é inválido')
    });

    const {
        handleSubmit,
        formState: {
            errors
        },
        register,
        setValue,
        reset
    } = useForm<Company>({
        resolver: zodResolver(formSchema)
    });

    const handle = async (company: Company) => {
        var response: Response<Company>;
        company = cleanFields(company);

        if (company.companyId == 0 && isValidCpfCnpj.length == 0) {
            response = await service.createCompany(company);
        } else {
            response = await service.updateCompany(company);
        }

        if (response.isSuccess) {
            reset();
            resetStates();
            props.fetchData();
            setIsValidCpfCnpj("");
        }

    }

    const cleanFields = (company: Company): Company => {
        company.name = company.name.trim();
        company.email = company.email.trim();
        company.cnpj = company.cnpj.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "").trim();
        company.phone = company.phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").trim();

        if (company.cnpj.length == 14 && !isValidCNPJ(company.cnpj)) {
            setIsValidCpfCnpj("O CNPJ e inválido")
        }

        if (company.cnpj.length == 11 && !isValidCPF(company.cnpj)) {
            setIsValidCpfCnpj("O CPF e inválido")
        }

        return company;
    }

    useEffect(() => {
        if (props.company != null) {
            setValue('companyId', props.company.companyId);
            setValue('name', props.company.name);
            setValue('cnpj', formatMaskCpfOrCnpj(props.company.cnpj));
            setValue('email', props.company.email);
            setValue('phone', formatMaskPhone(props.company.phone));

            resetStates();
            setIsValidCpfCnpj("");
        }
    }, [props.company?.companyId])
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const resetStates = () => {
        setHasCNPJ(false);
    }

    return (
        <Paper elevation={10} className="bg-white border-0 my-5 p-0 flex-col" style={{
            height: '85%',
            width: '100%'
        }}>
            <header className="w-full text-white text-center py-3 header" style={{
                borderRadius: '5px 5px 0px 0px'
            }}>FORMULARIO DE CADASTRO</header>

            <Form onSubmit={handleSubmit(handle)} className="w-full border-0">
                <div className="my-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        label="Nome do Fornecedor"
                        {...register('name')}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa)}
                        InputLabelProps={{ shrink: props.company ? true : undefined }}
                    />
                    {errors.name && <ErrorMessage errorMessage={errors.name.message} />}
                </div>

                <div className="mb-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        label="CNPJ/CPF"
                        InputLabelProps={{ shrink: props.company ? true : undefined }}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa) || hasCNPJ}
                        {...register('cnpj')}
                    />
                    <FormControlLabel control={<Checkbox {...label} checked={hasCNPJ} onChange={() => {
                        setValue('cnpj', '')
                        setHasCNPJ(!hasCNPJ);
                    }} />} label={"Não possuo o CNPJ/CPF do fornecedor"} />
                    {errors.cnpj && <ErrorMessage errorMessage={errors.cnpj.message} />}
                    {isValidCpfCnpj.length > 0 && <ErrorMessage errorMessage={isValidCpfCnpj} />}
                </div>

                <div className="mb-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        label="Email de Contato"
                        InputLabelProps={{ shrink: props.company ? true : undefined }}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa)}
                        {...register('email')}
                    />
                    {errors.email && <ErrorMessage errorMessage={errors.email.message} />}
                </div>

                <div className="mb-3">
                    <TextField
                        className="mb-2"
                        fullWidth
                        label="Telefone de Contato"
                        InputLabelProps={{ shrink: props.company ? true : undefined }}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa)}
                        {...register('phone')}
                    />
                    {errors.phone && <ErrorMessage errorMessage={errors.phone.message} />}
                </div>

                <div>
                    <MyButton type="submit" endIcon={<HiOutlineSave />}
                        disabled={!hasRole(RoleEnum.CadastrarEditarEmpresa)}
                    >
                        {props.company ? 'Atualizar' : 'Cadastrar'}
                    </MyButton>
                </div>
            </Form>
        </Paper>
    )
}