import { FC, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IoIosWarning } from 'react-icons/io';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { FaFileDownload } from "react-icons/fa";
import { BsUpload } from 'react-icons/bs';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import React from 'react';
import { Paper } from '@mui/material';
import { FiDownload } from 'react-icons/fi';
import { BiSolidCheckShield } from "react-icons/bi";

import { EventService } from '../../Services/EventService';
import { MyButton } from '../MyButton';
import ExcelConverter from '../../Helpers/ExcelConverter';
import { showErrorValidation } from '../../hooks/validation';

interface IProps {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    reloadPage: Function;
    eventId: number;
}


const ModalImportarPlanilhaExcel: FC<IProps> = (props) => {

    const service = new EventService();

    const [excelFile, setExcelFile] = useState(null);
    const [nameFile, setNameFile] = useState('SELECIONE A PLANILHA DE FUNCIONÁRIOS');
    const [logs, setLogs] = useState(new Array<string>());

    const handleClose = () => {
        props.setShow(false);
        props.reloadPage();
        setLogs([]);
    };

    const importarPlanilha = async (dados: any) => {

        let id = toast.loading("Importando funcionários...")
        service.importarPlanilhaFuncionarios(dados)
            .then(logs => {
                if (logs.length === 0) {
                    toast.update(id, { render: 'Funcionários importados com sucesso', type: 'success', isLoading: false, autoClose: 4000 })
                    handleClose();
                } else {
                    toast.update(id, { render: 'Não foi possivel importar a planilha, veja os logs para saber oque houve', type: 'success', isLoading: false, autoClose: 4000 });
                    setLogs(logs)
                }
            }).catch(error => {
                showErrorValidation(error)
            });
    }


    const handleSubmitExcel = async (e: any) => {
        e.preventDefault();

        if (excelFile !== null) {
            new ExcelConverter(excelFile).convertToEmployeesAsync()
                .then((convert: ExcelConverter) => {
                    if (convert.logs.length == 0)
                        importarPlanilha({ eventId: props.eventId, data: convert.data });
                    else {
                        setLogs(convert.logs)
                    }
                });
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const downloadExcel = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const planilha = XLSX.utils.json_to_sheet([{
            'Nome Completo *': '',
            'CPF/RG *': '',
            'Empresa *': '',
            'Setor *': '',
            'Função *': '',
            'Email': '',
        }]);

        const wb = { Sheets: { 'data': planilha }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Planilha de Funcionarios" + fileExtension);
    }

    return (
        <Modal show={props.show} size='lg'>

            {logs.length === 0 ? (
                <div id='modal-importar'>
                    <Modal.Header closeButton onHide={handleClose} style={{ textAlign: 'center' }}>
                        <Modal.Title className='w-full text-2xl'>Importar Planilha de Funcionários</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row px-4">
                            <Paper elevation={10} className='py-8 px-12'>
                                <span className='text-xl flex items-center'>
                                    Antes de realizar a importação dos funcionários certifique-se de que a planilha
                                    esteja no mesmo formato da planilha padrão.
                                </span>
                                <a onClick={downloadExcel} className='underline cursor-pointer text-xl flex items-center'>
                                    <BiSolidCheckShield className='text-2xl text-green-600' />clique aqui para baixar.
                                </a>
                            </Paper>
                        </div>
                        <div className="mt-4 px-4 row">
                            <Form style={{ border: '0px', padding: '5px 0px', width: '100%' }}>
                                <Button component="label"
                                    variant="contained"
                                    endIcon={<FaFileDownload />}
                                    onChange={(event: any) => {
                                        setExcelFile(event.target.files[0]);
                                        setNameFile(event.target.files[0].name);
                                    }}
                                    style={{ background: '#003958', color: 'white', width: '100%' }}>
                                    {nameFile}
                                    <VisuallyHiddenInput type="file" />
                                </Button>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <MyButton endIcon={<BsUpload />}
                            className="mx-2"
                            onClick={handleSubmitExcel}>
                            Realizar Importação
                        </MyButton>
                    </Modal.Footer>
                </div>
            ) : (
                <div id='modal-logs'>
                    <Modal.Header closeButton onHide={handleClose} style={{ textAlign: 'center' }}>
                        <Modal.Title style={{ width: '100%' }}>Logs da Importação</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', maxHeight: '450px' }}>
                        <ul className='py-3'>
                            {logs.map(log => {
                                return (
                                    <li style={{ borderBottom: '0.5px solid #ccc', marginBottom: '5px', listStyle: 'none' }}>
                                        <div className='flex justify-start items-center gap-2'>
                                            <IoIosWarning className='icon-warning' />
                                            <label>{log}</label>
                                        </div>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </Modal.Body>
                </div>
            )}
        </Modal>
    )
}

export default ModalImportarPlanilhaExcel;

